import React from 'react';
import { Stack } from '@mantine/core';
import SectionTitle from 'app/views/components/Headers&Text/SectionTitle';
import MainTable from 'app/views/components/Table/MainTable';
import { LetterOfCreditParams } from 'app/models/letter-of-credit';
import { columnDefs } from './locs-page-coumnDefs';
import LocsDetailPanel from './locs-detail-panel';


interface ExpandableMantineTableProps {
  locs: LetterOfCreditParams[];
  globalLoading: boolean;
  title: string;
}

const ExpandableMantineTable: React.FC<ExpandableMantineTableProps> = ({ locs, globalLoading, title }) => {
  const [isInactiveLocs, setIsInactiveLocs] = React.useState(false);

  const handleToggle = (isActive: boolean) => {
    setIsInactiveLocs(isActive);
  };

  // for now we do not have Inactive Locs but if we implement we will have with this logic

  const filteredLocs = React.useMemo(() => {
    if (isInactiveLocs) {
      return locs.filter((loc) => loc.status === 'Locked');
    }
    return locs.filter((loc) => loc.status !== 'Locked');
  }, [locs, isInactiveLocs]);

  const renderDetailPanel = (row: { original: LetterOfCreditParams; getIsExpanded: () => boolean }) => (
    <LocsDetailPanel row={row.original as any} isExpanded={row.getIsExpanded()} />
  );

  return <><SectionTitle>{title}</SectionTitle>
    <Stack w='100%' className='sectionRemoveGap sectionBody' >
      <Stack w='100%' pt='12px' className='sectionDetailBody' style={{ padding: '40px' }}>
        {columnDefs.length != 0 ? (
          <MainTable
            tableName={title}
            columnDefs={columnDefs}
            withPagination={true}
            data={filteredLocs ?? []}
            setRow={() => { }}
            renderDetailPanel={({ row }) => renderDetailPanel(row)}
            csvExportEnabled
            enableTopToolbar={false}
            headerBackgroundColor='#F0EEEE'
            isLoading={globalLoading}
            rowsPerPage={5}
            isAccordionTable={true}
            showBottomBoolean={true}
            isInactiveLoanLocs={isInactiveLocs}
            onToggle={(isActive) => handleToggle(isActive)}
            border={true}
          />) : null}
      </Stack>
    </Stack >
  </>;
};


export default ExpandableMantineTable;

