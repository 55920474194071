import React from "react";
import { Group, Stack, Divider, Button, Box, Text } from "@mantine/core";
import { ReactComponent as DeleteIcon } from "./DeleteIcon.svg";

interface DeleteConfirmationProps {
    onConfirm: () => void;
    onCancel: () => void;
    message?: string; // New optional prop for a custom confirmation message
}

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({
    onConfirm,
    onCancel,
    message,
}) => {
    return (
        <div className="delete-confirmation-overlay">
            <Box className="delete-confirmation-box">
                <Group spacing="sm" className="delete-confirmation-header">
                    <Box className="delete-confirmation-icon-box">
                        <DeleteIcon />
                    </Box>
                    <Stack pb="10px">
                        <Text className="delete-confirmation-title">Confirm</Text>
                        <Text className="delete-confirmation-message">
                            {message || "Are you sure you want to delete?"}
                        </Text>
                    </Stack>
                </Group>

                <Divider color="#C3C0C0" mt="md" pt="10px" />

                <Group position="right" mt="md">
                    <Button
                        variant="filled"
                        color="dark"
                        className="delete-button"
                        onClick={onConfirm}
                    >
                        Delete
                    </Button>
                    <Button
                        variant="outline"
                        color="gray"
                        className="cancel-button"
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                </Group>
            </Box>
        </div>
    );
};

export default DeleteConfirmation;
