import { MRT_ColumnDef } from 'mantine-react-table'
import { Text, Badge } from '@mantine/core'
import { ReactNode } from 'react'
import MyToDoActions from './todo-actions'
import { ToDoParams } from 'app/models/todo-params'
import { stringToDate, camelCaseToHumanReadable } from 'app/utils/util-functions'

export enum pillState {
  'NewTask' = 'NewTask',
  'Completed' = 'Completed',
  'InProgress' = 'InProgress',
  'Overdue' = 'Overdue',
  'Archived' = 'Archived',
}

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export const colsTodo: MRT_ColumnDef<ToDoParams>[] = [
  {
    size: 80,
    accessorKey: 'taskType',
    header: 'Task Type',
    Header: <TableHead header={'Task Type'} />,
    Cell: ({ cell }) => {
      const taskType = cell.getValue<string>();
      const formattedTaskType = camelCaseToHumanReadable(taskType);
      return <TableRowText text={formattedTaskType} />;
    },
  },
  {
    size: 100,
    accessorKey: 'dueDate',
    header: 'Due Date',
    Header: <TableHead header={'Due Date'} />,
    accessorFn: row => {
      const dueDate = row.dueDate instanceof Date ? row.dueDate : stringToDate(row.dueDate);
      return dueDate;
    },
    mantineFilterTextInputProps: {
      placeholder: 'Date',
    },
    filterVariant: 'date',
    filterFn: 'lessThanOrEqualTo',
    sortingFn: 'datetime',
    Cell: ({ cell }) => (
      <Text fz="1.5em">{cell.getValue<Date>()?.toLocaleDateString()}</Text>
    ), //render Date as a string
  },
  {
    size: 80,
    accessorFn: row => new Date(row.timeDue), //convert to Date for sorting and filtering
    enableColumnFilter: false,
    accessorKey: 'timeDue',
    header: 'Time',
    Header: <TableHead header={'Time'} />,
    sortingFn: 'datetime',
    Cell: ({ cell }) => (
      <Text fz="1.5em">{cell.getValue<Date>()?.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      }) ?? 'N/A'}</Text>
    ), //render Date as a string
  },
  {
    size: 100,
    accessorKey: 'status',
    header: 'Status',
    Header: <TableHead header={'Status'} />,
    Cell: cell => {
      let state = cell.row.original.status
      if (cell.row.original.overdue) {
        state = 'Overdue'
      }
      let styles = {}
      if (state === pillState.NewTask) {
        state = 'NEW TASK'
        styles = {
          backgroundColor: '#FF5A1F',
        }
      }

      if (state === pillState.Completed) {
        styles = {
          backgroundColor: '#E8006E',
        }
      }
      if (state === pillState.InProgress) {
        state = 'IN PROGRESS'
        styles = {
          backgroundColor: '#7E3AF2',
        }
      }
      if (state === pillState.Overdue) {
        styles = {
          backgroundColor: 'red',
        }
      }

      if (state === pillState.Archived) {
        styles = {
          backgroundColor: '#43329a',
        }
      }

      return (
        <Badge
          styles={{
            root: {
              fontFamily: 'Plus Jakarta Sans',
              color: 'white',
              borderRadius: '4px',
              padding: '1.5em',
              ...styles,
            },
          }}
        >
          <TableRowText text={state} />
        </Badge>
      )
    },
  },
  {
    size: 80,
    accessorKey: 'priority',
    header: 'Priority',
    Header: <TableHead header={'Priority'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 300,
    accessorKey: 'notes',
    header: 'Notes',
    Header: <TableHead header={'Notes'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 100,
    accessorKey: 'id',
    header: 'Actions',
    Header: <TableHead header={'Actions'} />,
    enableColumnActions: false,
    enableColumnFilter: false,
    Cell: cell => <MyToDoActions row={cell.row.original} />,
  },
]