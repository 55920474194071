import React from 'react';
import { Stack } from '@mantine/core';
import { MasterTradeParams } from 'app/models/master-trade-params';
import MainTable from 'app/views/components/Table/MainTable';
import { columnDefs } from './trade-entry-panel-columnDefs';

interface DetailPanelProps {
  row: MasterTradeParams[];
  isExpanded: boolean;
}

const TradeEntryPanel: React.FC<DetailPanelProps> = ({ row }) => {
  return (
    <Stack w="100%" className="sectionDetailTradeBody noHeader">
      <Stack w='100%' className='sectionRemoveGap sectionBody' >
        <Stack w='100%' className='sectionDetailTradeBody' style={{ padding: '10px' }}>
          {columnDefs.length != 0 ? (
            <MainTable
              tableName={'AA'}
              columnDefs={columnDefs}
              withPagination={false}
              data={row as any ?? []}
              setRow={() => { }}
              enableTopToolbar={false}
              headerBackgroundColor='#F0EEEE'
              enableColumnOrdering={false}
              enableColumnResizing={false}
              enableGrouping={false}
              enableColumnFilters={false}
              enableColumnActions={false}
              enableFilters={false}
              enableSorting={false}
              enableFullScreenToggle={false}
              enableDensityToggle={false}
              enableHiding={false}
              border={true}
              fromTradeEntry={false}
            />) : null}
        </Stack>
      </Stack >
    </Stack >
  );
};

export default TradeEntryPanel;
