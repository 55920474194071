import { Group, Text } from '@mantine/core'
import { WireInstructionParams } from 'app/models/wire-instruction-params'
import CustomHoverCard from './custom-hover-card'
import '../../../styles/components/hover-card-wire-instruction.css'

interface Props {
  wireInstruction: WireInstructionParams
}

const WireInstructionHoverCard = ({ wireInstruction }: Props) => {
  const cardTitle = 'Wire Instruction'
  const targetComponent = (
    <Text className='sectionItemTitleClickable text-15'>
      {wireInstruction.currency}
    </Text>
  )

  const wireInstructionData = [
    { label: 'Currency', value: wireInstruction.currency },
    { label: 'Bank ABA #', value: wireInstruction.accountWithInstitutionBankAba },
    { label: 'Account with Institution Name', value: wireInstruction.accountWithInstitutionName },
    { label: 'Beneficiary Customer Name', value: wireInstruction.beneficiaryCustomerName },
    { label: 'Beneficiary Account #', value: wireInstruction.beneficiaryAccountNumber },
    { label: 'Payment Reference', value: wireInstruction.paymentReference },
  ]

  return (
    <CustomHoverCard cardTitle={cardTitle} targetComponent={targetComponent}>
      <div className='hover-card-wi-container'>
        <div className='hover-card-wi-content'>
          {wireInstructionData.map((data, index) => (
            <Group key={index} position='apart' className='hover-card-wi-group'>
              <Text className='hover-card-wi-text bold'>{data.label}</Text>
              <Text className='hover-card-wi-text'>{data.value}</Text>
            </Group>
          ))}
        </div>
      </div>
    </CustomHoverCard>
  )
}

export default WireInstructionHoverCard
