import { Menu } from '@mantine/core';
import PrimaryDropDown from 'app/views/components/DropDown/DropDown';
import { useDispatch } from 'react-redux';
import { LoanRateSettingParams } from 'app/models/loan-rate-setting-params';
import {
  approveLoanRateSetting,
  deleteLoanRateSetting,
  rejectLoanRateSetting,
  submitLoanRateSetting,
} from './rate-setting-daml-actions';
import IonIcon from '@reacticons/ionicons';
import { useState } from 'react';
import DeleteConfirmation from 'app/views/components/delete-confirmation/DeleteConfirmation';

type Props = {
  row: LoanRateSettingParams;
};

export default function LoanRateSettingActions({ row }: Props) {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete modal

  const handleDelete = async () => {
    await deleteLoanRateSetting(row, dispatch); // Perform delete action
    setShowDeleteModal(false); // Close modal
  };

  return (
    <div>
      <Menu shadow="md" width={200} position="bottom" withinPortal>
        <Menu.Target>
          <div style={{ width: 'fit-content' }}>
            <PrimaryDropDown>Actions</PrimaryDropDown>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          {row?.status?.toLowerCase() === 'draft' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name="swap-horizontal-outline" />}
              onClick={() => {
                submitLoanRateSetting(row, dispatch);
              }}
            >
              Submit For Approval
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() === 'submitted' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name="swap-horizontal-outline" />}
              onClick={() => {
                approveLoanRateSetting(row, dispatch);
              }}
            >
              Approve
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() === 'approved' ? (
            <Menu.Item icon={<IonIcon className="action-ion-icon" name="open-outline" />}>
              Amendment
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() !== 'approved' ? (
            <>
              <Menu.Divider />
              <Menu.Label>Danger zone</Menu.Label>
            </>
          ) : null}

          {row?.status?.toLowerCase() === 'draft' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name="trash-outline" />}
              onClick={() => setShowDeleteModal(true)} // Show delete modal
            >
              Delete
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() === 'submitted' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name="swap-horizontal-outline" />}
              onClick={() => {
                rejectLoanRateSetting(row, dispatch);
              }}
            >
              Reject
            </Menu.Item>
          ) : null}
        </Menu.Dropdown>
      </Menu>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <DeleteConfirmation
          onConfirm={handleDelete}
          onCancel={() => setShowDeleteModal(false)}
          message="Delete This Loan Rate Setting?"
        />
      )}
    </div>
  );
}
