import React, { useEffect, useState } from 'react';
import { Paper, Group, Button, TextInput } from '@mantine/core';
import RolesSection from './entity-profile-form/roles-section';
import CheckboxField from './entity-profile-form/checkbox-field';
import StatusSwitch from '../../components/status-switch';
import { EntityProfileParams, EntityRole } from 'app/models/entity-profile-params';
import { useDispatch } from 'react-redux';
import { saveEntityProfile } from 'app/state/ducks/entity-profile/thunks';
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification';
import { useForm } from '@mantine/form';
import { NewSelectInput } from 'app/views/components/inputs/selects/NewSelectInput';
import { kycTypeOptions } from 'app/models/dropdown-options';


interface EntityFormProps {
    newEntity: EntityProfileParams | undefined;
    setFormExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const EntityForm: React.FC<EntityFormProps> = ({ newEntity, setFormExpanded }) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const handleCreateEntity = async () => {
        setIsLoading(true)
        try {
            const validatedForm = form.validate()
            if (validatedForm.hasErrors) return
            const values = form.values;
            form.validate()
            const result: any = await dispatch(saveEntityProfile(values, values.id));
            if (!result.success) {
                ErrorNotification({ message: result.payload, title: 'Save Entity Profile Failed' });
                return;
            }
            SuccessNotification({
                message: 'The entity profile has been successfully saved.',
                title: 'Save Successful',
            });
            setFormExpanded(false);
            form.reset();
        } catch (error) {
            console.log(error)
            ErrorNotification({
                message: 'An error occurred while saving the entity profile.',
                title: 'Save Failed',
            });
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        if (newEntity) {
            form.setValues(newEntity);
        }

    }, [newEntity]);

    const form: any = useForm({
        initialValues: newEntity ?? {
            name: '',
            isExternalUser: false,
            isMyCompany: false,
            kycType: '',
            roles: [] as EntityRole[],
            isActive: false
        },
        validate: {
            name: (value) => (value.trim() === '' ? 'Name is required' : null),
            kycType: (value) => (value.trim() === '' ? 'KYC Type is required' : null),
            roles: (value) => (value.length === 0 ? 'At least one role is required' : null),
        },
        validateInputOnBlur: true,
        validateInputOnChange: true,
    })

    return (
        <Paper
            p="lg"
            radius="md"
            className="entity-form-paper"
            style={{ borderRadius: newEntity ? '0px 0px 12px 12px' : '12px' }}
        >
            <form onSubmit={handleCreateEntity}>
                <Group spacing="lg" mb="md">
                    <TextInput
                        placeholder="Add Entity Profile Name"
                        className="entity-name-input"
                        {...form.getInputProps('name')}
                    />
                    <NewSelectInput label="KYC Type" data={kycTypeOptions} w="195px" placeholder='KYC' {...form.getInputProps('kycType')} />
                    <CheckboxField label="External User" checked={form.values.isExternalUser} onChange={(checked) => form.setFieldValue('isExternalUser', checked)} />
                    <CheckboxField label="My Company" checked={form.values.isMyCompany} onChange={(checked) => form.setFieldValue('isMyCompany', checked)} />
                </Group>

                <RolesSection
                    roles={form.values.roles}
                    errorMsg={form.errors.roles || null}
                    onToggleRole={(role, checked) => {
                        const updatedRoles = checked
                            ? [...form.values.roles, role] // Add role if checked
                            : form.values.roles.filter((r: EntityRole) => r !== role); // Remove role if unchecked
                        form.setFieldValue('roles', updatedRoles);
                    }}
                />
                <Group align="center" mb="md" sx={{ width: '100%' }} position="apart">
                    <StatusSwitch
                        isActive={form.values.isActive}
                        onToggle={(isActive) => form.setFieldValue('isActive', isActive)}
                    />
                    <Group className="form-actions-group">
                        <Button
                            variant="outline"
                            onClick={() => setFormExpanded(false)}
                            className="form-actions-button cancel"
                            loading={isLoading}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleCreateEntity}
                            className="form-actions-button save"
                            loading={isLoading}
                        >
                            Save
                        </Button>
                    </Group>
                </Group>
            </form>
        </Paper>
    );
};

export default EntityForm;
