import { HoverCard, Stack, HoverCardProps } from '@mantine/core';
import { ReactNode } from 'react';
import '../../../styles/components/hover-card.css'

interface Props extends HoverCardProps {
    targetComponent: ReactNode; // Custom content which will be the target for the HoverCard (such as an icon)
    cardTitle: string; // Customizable title
    children: ReactNode; // custom content for the hovercard
}

export default function CustomHoverCard({
    targetComponent,
    cardTitle,
    children,
    width = '580px',
    withArrow = true,
    withinPortal = true,
    position = 'right',
    arrowSize = 16,
    arrowOffset = 0,
    shadow = 'md',
    ...props
}: Props) {
    return (
        <HoverCard
            width={width}
            withArrow={withArrow}
            withinPortal={withinPortal}
            position={position}
            arrowSize={arrowSize}
            arrowOffset={arrowOffset}
            shadow={shadow}
            {...props}
        >
            <HoverCard.Target>
                <div className='hover-card-target'>
                    {targetComponent}
                </div>
            </HoverCard.Target>

            <HoverCard.Dropdown className='hover-card'>
                <div>
                    {cardTitle && <div className='hover-card-title'>{cardTitle}</div>}
                    <Stack align='flex-start'>
                        {children}
                    </Stack>
                </div>
            </HoverCard.Dropdown>
        </HoverCard>
    );
}