import { useForm } from '@mantine/form'
import { TradeEntryFormPresentation } from './trade-entry-form-presentation'
import { useDispatch, useSelector } from 'react-redux'
import { getFacilities } from 'app/state/ducks/facilities/selectors'
import { getDeals } from 'app/state/ducks/deals/selectors'
import { useEffect } from 'react'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { formatDateToUTC } from 'app/utils/util-functions'
import { MasterTradeParams } from 'app/models/master-trade-params'
import { saveMasterTrade } from 'app/state/ducks/master-trades/thunks'
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks'
import { loadAllDeals } from 'app/state/ducks/deals/thunks'
import { loadEntities } from 'app/state/ducks/entities/thunks'

export default function TradeEntryFormLogic() {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(loadAllFacilities())
        dispatch(loadAllDeals())
        dispatch(loadEntities())
    }, [])

    const facilities = useSelector(getFacilities)
    const deals = useSelector(getDeals)
    const entities = useSelector(getEntities)

    const form: any = useForm({
        initialValues: {
            id: undefined,
            clientId: '',
            trader: '',
            pm: '',
            desk: '',
            tradeGroupId: '',
            sellerId: '',
            buyerId: '',
            myEntity: 'Buy',
            seller: null,
            buyer: null,
            dealId: '',
            deal: null,
            tradeDate: null,
            tradeType: null,
            tradeAssociation: null,
            transferType: null,
            interestTreatment: null,
            assignmentFeePayType: null,
            tradeDocsType: null,
            includeDelayCompensation: true,
            delayCompensationDate: null,
            tradedFacilities: [],
        },
        transformValues: (values): MasterTradeParams => {
            const seller = entities.find((entity: any) => entity.id === values.sellerId)
            const buyer = entities.find((entity: any) => entity.id === values.buyerId)
            const deal = deals.find((deal: any) => deal.id === values.dealId)
            const tradeDate = values.tradeDate ? formatDateToUTC(values.tradeDate) : null
            const delayCompensationDate = !values.includeDelayCompensation
                ? null
                : values.delayCompensationDate
                    ? formatDateToUTC(values.delayCompensationDate)
                    : tradeDate

            return {
                ...values,
                myEntity: values.myEntity ?? 'Buy',
                delayCompensationDate: delayCompensationDate,
                tradeDate: tradeDate,
                sellerId: { id: seller?.id ?? '', admin: seller?.maker ?? '' },
                buyerId: { id: buyer?.id ?? '', admin: buyer?.maker ?? '' },
                dealId: { id: deal?.id ?? '', admin: deal?.accountManagementAdmin ?? '' },
                tradedFacilities: values.tradedFacilities.map((tf: any) => {
                    const facility = facilities.find((f: any) => f.id === tf.facilityId);
                    return {
                        facilityId: { id: facility?.id ?? '', admin: facility?.accountManagementAdmin ?? '' },
                        tradeAmount: tf.tradeAmount,
                        price: tf.price,
                        currentAmount: tf.tradeAmount,
                        facilityDetails: null

                    }
                }),
            }
        },
    })


    const createTradeEntry = async () => {
        const values = form.getTransformedValues()
        console.log('values', values)
        const result: any = await dispatch(saveMasterTrade(values))
        if (!result.success) {
            ErrorNotification({
                title: 'Trade Entry Failed',
                message: result.payload || 'Action Failed',
            })
            return
        }
        SuccessNotification({
            title: 'Trade Entry Successful',
            message: 'Trade Entry has been saved in draft mode',
        })
        form.reset()
    }

    const cancelTradeEntry = () => {
        form.reset()
    }

    const onDealChange = (value?: string | null) => {

        if (!value) {
            form.setFieldValue('dealId', '')
            form.setFieldValue('deal', null)
            form.setFieldValue('tradedFacilities', [])
            return
        }
        const deal = deals.find((deal: any) => deal.id === value)
        if (!deal) {
            form.setFieldValue('dealId', '')
            form.setFieldValue('deal', null)
            form.setFieldValue('tradedFacilities', [])
            return
        }

        form.setFieldValue('dealId', deal.id)
        form.setFieldValue('deal', deal)

        const dealFacilities = facilities.filter((facility: any) => facility.dealId.id === value)
        const facilityValues = dealFacilities.map((facility: any) => ({
            facilityId: facility.id,
            tradeAmount: 0.0,
            price: 0.0,
            currentAmount: 0.0,
            facility: facility,
        }))
        form.setFieldValue('tradedFacilities', facilityValues)
    }

    const onBuyerSellerChange = (value: any) => {
        if (!value) {
            form.setFieldValue('seller', form.values.buyer)
            form.setFieldValue('buyer', form.values.seller)
            return
        }
        form.setFieldValue('myEntity', value)
        form.setFieldValue('sellerId', form.values.buyerId)
        form.setFieldValue('buyerId', form.values.sellerId)
        form.setFieldValue('seller', form.values.buyer)
        form.setFieldValue('buyer', form.values.seller)
    }

    return (
        <TradeEntryFormPresentation form={form} onDealChange={onDealChange} onByerSellerChange={onBuyerSellerChange} createTradeEntry={createTradeEntry} cancelTradeEntry={cancelTradeEntry} />
    )
}