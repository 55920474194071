import React, { useEffect } from 'react';
import { Stack } from '@mantine/core';
import SectionTitle from 'app/views/components/Headers&Text/SectionTitle';
import MainTable from 'app/views/components/Table/MainTable';
import { MasterTradeParams } from 'app/models/master-trade-params';
import { columnDefs } from './trade-enty-page-columnDefs';
import { loadAllDeals } from 'app/state/ducks/deals/thunks';
import { getDeals } from 'app/state/ducks/deals/selectors';
import { useDispatch, useSelector } from 'react-redux'
import { loadEntities } from 'app/state/ducks/entities/thunks';
import { getEntities } from 'app/state/ducks/entities/selectors';
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks';
import { getFacilities } from 'app/state/ducks/facilities/selectors';
import TradeEntryPanel from './trade-entry-panel';



interface ExpandableMantineTableProps {
    masterTrades: MasterTradeParams[];
    globalLoading: boolean;
    title: string;
}

const ExpandableMantineTable: React.FC<ExpandableMantineTableProps> = ({ masterTrades, globalLoading, title }) => {
    const dispatch = useDispatch()
    const [, setIsInactiveLoan] = React.useState(false);
    const allDeals = useSelector(getDeals)
    const allEntities = useSelector(getEntities)
    const allFacilities = useSelector(getFacilities)


    const handleToggle = (isActive: boolean) => {
        setIsInactiveLoan(isActive);
    };

    useEffect(() => {
        dispatch(loadAllDeals())
        dispatch(loadEntities())
        dispatch(loadAllFacilities())
    }, [])

    const refinedMasterTrades = masterTrades.map(trade => {
        const matchingDeal = allDeals.find(deal => deal.id === trade?.dealId?.id);
        const matchingSeller = allEntities.find(entity => entity.id === trade.sellerId?.id);
        const matchingBuyer = allEntities.find(entity => entity.id === trade.buyerId?.id);

        const refinedFacilities = trade.tradedFacilities.map(facility => {
            const matchingFacility = allFacilities.find(f => f.id === facility.facilityId.id) || null;

            return {
                ...facility,
                facilityDetails: matchingFacility
            };
        });

        return {
            ...trade,
            dealDetails: matchingDeal || null,
            sellerEntity: matchingSeller || null,
            buyerEntity: matchingBuyer || null,
            tradedFacilities: refinedFacilities
        };
    });


    const renderDetailPanel = (row: { original: MasterTradeParams; getIsExpanded: () => boolean }) => (
        <TradeEntryPanel row={refinedMasterTrades} isExpanded={row.getIsExpanded()} />
    );

    return <><SectionTitle>{title}</SectionTitle>
        <Stack w='100%' className='sectionRemoveGap sectionBody' >
            <Stack w='100%' pt='12px' className='sectionDetailBody' style={{ padding: '40px' }}>
                {columnDefs.length != 0 ? (
                    <MainTable
                        tableName={title}
                        columnDefs={columnDefs}
                        withPagination={true}
                        data={refinedMasterTrades ?? []}
                        setRow={() => { }}
                        renderDetailPanel={({ row }) => renderDetailPanel(row)}
                        csvExportEnabled
                        enableTopToolbar={false}
                        headerBackgroundColor='#F0EEEE'
                        isLoading={globalLoading}
                        rowsPerPage={5}
                        isAccordionTable={true}
                        showBottomBoolean={false}
                        onToggle={(isActive) => handleToggle(isActive)}
                        border={true}
                        fromTradeEntry={true}
                        enableColumnOrdering={false}
                        enableColumnResizing={false}
                        enableGrouping={false}
                        enableColumnFilters={false}
                        enableColumnActions={false}
                        enableFilters={false}
                        enableSorting={false}
                        enableFullScreenToggle={false}
                        enableDensityToggle={false}
                        enableHiding={false}
                    />) : null}
            </Stack>
        </Stack >
    </>;
};


export default ExpandableMantineTable;

