import { MRT_ColumnDef } from 'mantine-react-table'
import { Text, } from '@mantine/core'
import { ReactNode } from 'react'
import StatusPill from '../../common/pill-status'
import { formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'
import LetterOfCreditActions from '../letter-of-credit-actions'
import { LetterOfCreditParams } from 'app/models/letter-of-credit'

function TableHead({ header }: { header: string }) {
    return (
        <Text
            style={{
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '19px',
                color: '#413E3E',
            }}
        >
            {header}
        </Text>
    )
}

function TableRowText({ text }: { text: ReactNode }) {
    return (
        <Text
            style={{
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '19px',
                color: '#413E3E',
            }}
        >
            {text}
        </Text>
    )
}
export const columnDefs: MRT_ColumnDef<LetterOfCreditParams>[] = [
    {
        size: 40,
        accessorKey: 'effectiveDate',
        header: 'Effective Date',
        Header: <TableHead header={'Effective Date'} />,
        accessorFn: row => {
            return stringToDate(row.effectiveDate);
        },
        mantineFilterTextInputProps: {
            placeholder: 'Date',
        },
        filterFn: 'lessThanOrEqualTo',
        filterVariant: 'date',
        sortingFn: 'datetime',
        Cell: ({ cell }) => {
            const formattedDate = cell.getValue<Date>()?.toLocaleDateString() ?? '';
            return <TableRowText text={formattedDate} />;
        },
    },
    {
        size: 40,
        accessorKey: 'expirationDate',
        header: 'Expiration Date',
        Header: <TableHead header={'Expiration Date'} />,
        accessorFn: row => {
            return stringToDate(row.initialExpirationDate);
        },
        mantineFilterTextInputProps: {
            placeholder: 'Date',
        },
        filterFn: 'lessThanOrEqualTo',
        filterVariant: 'date',
        sortingFn: 'datetime',
        Cell: ({ cell }) => {
            const formattedDate = cell.getValue<Date>()?.toLocaleDateString() ?? '';
            return <TableRowText text={formattedDate} />;
        },
    },
    {
        size: 50,
        accessorKey: 'amount',
        header: 'Amount',
        Header: <TableHead header={'Amount'} />,
        Cell: ({ cell, row }: any) => {
            const value = cell?.getValue() ?? 0;
            const currency = row.original?.currency ?? 'USD';
            return (
                <TableRowText text={formatNumberToCurrency(value, currency)} />
            );
        },
    },
    {
        size: 40,
        accessorKey: 'status',
        header: 'Status',
        Header: <TableHead header={'Status'} />,
        Cell: cell => {
            return (
                <StatusPill
                    state={cell.row.original.status ?? 'Draft'}
                />
            )
        },
    },
    {
        size: 40,
        accessorKey: 'id',
        header: 'Actions',
        Header: <TableHead header={'Actions'} />,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: cell => <LetterOfCreditActions item={cell.row.original} />,
    },
]
