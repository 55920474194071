import { Menu } from '@mantine/core';
import PrimaryDropDown from 'app/views/components/DropDown/DropDown';
import { ToDoParams } from 'app/models/todo-params';
import { useState } from 'react';
import { ToDoModal } from './todo-modal';
import { useDispatch } from 'react-redux';
import { deleteTodo, updateTodoStatus } from './todo-daml-actions';
import IonIcon from '@reacticons/ionicons';
import DeleteConfirmation from 'app/views/components/delete-confirmation/DeleteConfirmation';

interface actionParam {
  row: ToDoParams;
}

export default function MyToDoActions({ row }: actionParam) {
  const [openEdit, setOpenEdit] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete confirmation modal
  const dispatch = useDispatch();

  const handleDelete = async () => {
    await deleteTodo(row.id || '', dispatch); // Perform delete action
    setShowDeleteModal(false); // Close the modal
  };

  return (
    <div>
      <ToDoModal setOpen={() => setOpenEdit(false)} todo={row} open={openEdit} />

      <Menu shadow="md" width={200} position="bottom" withinPortal>
        <Menu.Target>
          <div style={{ width: 'fit-content' }}>
            <PrimaryDropDown>Actions</PrimaryDropDown>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            icon={<IonIcon className="action-ion-icon" name="settings-outline" />}
            onClick={() => setOpenEdit(true)}
          >
            Edit
          </Menu.Item>

          {row.status.toLowerCase() === 'newtask' && (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name="arrow-forward-outline" />}
              onClick={() => {
                updateTodoStatus(row, 'InProgress', dispatch);
              }}
            >
              In Progress
            </Menu.Item>
          )}

          {(row.status.toLowerCase() === 'inprogress' || row.status.toLowerCase() === 'overdue') && (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name="arrow-forward-outline" />}
              onClick={() => {
                updateTodoStatus(row, 'Completed', dispatch);
              }}
            >
              Completed
            </Menu.Item>
          )}

          {row.status.toLowerCase() === 'completed' && (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name="arrow-forward-outline" />}
              onClick={() => {
                updateTodoStatus(row, 'Archived', dispatch);
              }}
            >
              Archive
            </Menu.Item>
          )}

          {row.status.toLowerCase() === 'inprogress' && (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name="arrow-back-outline" />}
              onClick={() => {
                updateTodoStatus(row, 'NewTask', dispatch);
              }}
            >
              New Task
            </Menu.Item>
          )}

          {row.status.toLowerCase() === 'completed' && (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name="arrow-back-outline" />}
              onClick={() => {
                updateTodoStatus(row, 'InProgress', dispatch);
              }}
            >
              In Progress
            </Menu.Item>
          )}

          <Menu.Item
            color="red"
            icon={<IonIcon className="action-ion-icon" name="trash-outline" />}
            onClick={() => setShowDeleteModal(true)} // Show delete confirmation modal
          >
            Delete
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <DeleteConfirmation
          onConfirm={handleDelete}
          onCancel={() => setShowDeleteModal(false)}
          message="Delete This To Do?"
        />
      )}
    </div>
  );
}
