import { Menu, Modal } from '@mantine/core';
import PrimaryDropDown from 'app/views/components/DropDown/DropDown';
import { useDispatch } from 'react-redux';
import {
  submitLetterOfCredit,
  deleteLetterOfCredit,
  rejectLetterOfCredit,
  approveLetterOfCredit,
} from './letter-of-credit-daml-actions';
import { useState } from 'react';
import LetterOfCreditForm from './letter-of-credit-form-logic';
import { LetterOfCreditParams } from 'app/models/letter-of-credit';
import LocHistoryForm from './history/history';
import IonIcon from '@reacticons/ionicons';
import DeleteConfirmation from 'app/views/components/delete-confirmation/DeleteConfirmation';

type Props = {
  item: LetterOfCreditParams;
};

export default function LetterOfCreditActions({ item }: Props) {
  const dispatch = useDispatch();

  const [openEdit, setOpenEdit] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [letterOfCredit, setLetterOfCredit] = useState<LetterOfCreditParams>();
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete confirmation modal

  function editLetterOfCredit(): any {
    setLetterOfCredit(item);
    setOpenEdit(true);
  }

  function displayHistory() {
    setLetterOfCredit(item);
    setOpenHistory(true);
  }

  const handleDelete = async () => {
    await deleteLetterOfCredit(item, dispatch); // Perform the delete action
    setShowDeleteModal(false); // Close the modal
  };

  return (
    <>
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <div style={{ width: 'fit-content' }}>
            <PrimaryDropDown>Actions</PrimaryDropDown>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Label>Letter of Credit</Menu.Label>
          {item?.status?.toLowerCase() === 'draft' ? (
            <>
              <Menu.Item
                icon={<IonIcon className="action-ion-icon" name="settings-outline" />}
                onClick={() => editLetterOfCredit()}
              >
                Edit
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  submitLetterOfCredit(item, dispatch);
                }}
                icon={<IonIcon className="action-ion-icon" name="open-outline" />}
              >
                Submit For Approval
              </Menu.Item>
            </>
          ) : null}

          {item?.status?.toLowerCase() === 'submitted' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name="swap-horizontal-outline" />}
              onClick={() => {
                approveLetterOfCredit(item, dispatch);
              }}
            >
              Approve
            </Menu.Item>
          ) : null}

          {item?.status?.toLowerCase() === 'approved' ? (
            <Menu.Item
              onClick={() => displayHistory()}
              icon={<IonIcon className="action-ion-icon" name="swap-horizontal-outline" />}
            >
              History
            </Menu.Item>
          ) : null}

          {item?.status?.toLowerCase() !== 'approved' ? (
            <>
              <Menu.Divider />
              <Menu.Label>Danger zone</Menu.Label>
            </>
          ) : null}

          {item?.status?.toLowerCase() === 'draft' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name="trash-outline" />}
              onClick={() => setShowDeleteModal(true)} // Show delete confirmation modal
            >
              Delete
            </Menu.Item>
          ) : null}

          {item?.status?.toLowerCase() === 'submitted' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name="swap-horizontal-outline" />}
              onClick={() => {
                rejectLetterOfCredit(item, dispatch);
              }}
            >
              Reject
            </Menu.Item>
          ) : null}
        </Menu.Dropdown>
      </Menu>

      <Modal
        size="xl"
        className="modal-body modal-body-template fit-content-modal"
        opened={openEdit}
        onClose={() => setOpenEdit(false)}
      >
        <LetterOfCreditForm
          letterOfCredit={letterOfCredit}
          close={() => setOpenEdit(false)}
        />
      </Modal>

      <Modal
        size="90%"
        opened={openHistory}
        onClose={() => setOpenHistory(false)}
      >
        {letterOfCredit && (
          <LocHistoryForm
            locId={letterOfCredit.id ?? ''}
            close={() => setOpenHistory(false)}
          />
        )}
      </Modal>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <DeleteConfirmation
          onConfirm={handleDelete} // Handle the delete action
          onCancel={() => setShowDeleteModal(false)} // Close the modal on cancel
          message="Delete This Letter of Credit?" // Custom message
        />
      )}
    </>
  );
}
