import { useEffect, useState } from 'react'
import { EntityParams } from 'app/models/entity-params'
import { entityCols, EntityColsTableDefs, StatusState } from './entities-table-columnDefs'
import { Stack } from '@mantine/core'
import { useSelector } from 'react-redux'
import { getEntities, getEntitiesLoader } from 'app/state/ducks/entities/selectors'
import { useNavigate } from 'react-router-dom'
import MainTable from 'app/views/components/Table/MainTable'
import KPI_Section from 'app/views/components/KPI/KPI-horizontal-section'
import { getKPIData } from './entity-kpi-data'
import { getEntityProfiles } from 'app/state/ducks/entity-profile/selectors'
import { getEntityTypes } from 'app/state/ducks/entity-type/selectors'

export function ManageEntities() {
  const navigate = useNavigate()
  const entitiesLoader = useSelector(getEntitiesLoader)
  const [globalLoading, setGlobalLoading] = useState(false)
  const entities: EntityParams[] = useSelector(getEntities)
  const entityProfiles = useSelector(getEntityProfiles)
  const entityTypes = useSelector(getEntityTypes)
  const [filteredEntities, setFilteredEntities] = useState<EntityParams[]>(useSelector(getEntities))

  const kpiData = getKPIData(entities, setFilteredEntities)

  useEffect(() => {
    setGlobalLoading(entitiesLoader)
  }, [entitiesLoader])


  useEffect(() => {
    setFilteredEntities(entities)
  }, [entities])

  const cleanEntitesData = (entities: EntityParams[]): EntityColsTableDefs[] => {
    return entities
      .sort((a, b) => a.entityName.localeCompare(b.entityName))
      .map(entity => ({
        entityName: entity.entityName,
        internalId: entity.internalId ?? '',
        status: entity.status === 'Active' ? StatusState.Active : StatusState.Inactive,
        mei: entity.mei ?? '',
        kycType: entity.kycType || 'N/A',
        entityType: entityTypes?.find(type => type.id === entity?.entityType?.id)?.name ?? 'N/A',
        entityProfile: !entity.entityProfile || entity.entityProfile.length == 0
          ? ''
          : entity.entityProfile.length > 1
            ? 'Multiple'
            : entityProfiles.find(profile => profile.id === entity.entityProfile[0].id)?.name ?? '',
        parent: entity.parent ?? entity.fundManager ?? '',
        taxFormStatus: entity.taxFormStatus ?? '',
        kycStatus: entity.kycStatus ?? '',
        id: entity.id ?? '',
        original: entity,
      }))

  }

  return (
    <Stack>
      <KPI_Section kpiData={kpiData} isLoading={globalLoading} />
      <MainTable
        tableName='Entities Table'
        isLoading={globalLoading}
        csvExportEnabled={true}
        columnDefs={entityCols}
        enableTopToolbar={false}
        headerBackgroundColor='#F0EEEE'
        data={cleanEntitesData(filteredEntities)}
        setRow={(e: any, cellName: string) => {
          if (cellName != 'id') {
            navigate('/entitymanagement/entity/' + e.id + '/' + e.status)
          }
        }}
      />
    </Stack>
  )
}
