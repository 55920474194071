import { Menu } from '@mantine/core';
import PrimaryDropDown from 'app/views/components/DropDown/DropDown';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FeePaymentParams } from 'app/models/fee-payment-params';
import {
  approveFeePayment,
  deleteFeePayment,
  rejectFeePayment,
  submitFeePayment,
} from './fee-payment-daml-actions';
import IonIcon from '@reacticons/ionicons';
import DeleteConfirmation from 'app/views/components/delete-confirmation/DeleteConfirmation';

type Props = {
  row: FeePaymentParams;
};

export default function FeePaymentPaymentActions({ row }: Props) {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Delete modal state

  const handleDelete = async () => {
    await deleteFeePayment(row, dispatch); // Execute delete action
    setShowDeleteModal(false); // Close the modal after deletion
  };

  return (
    <div>
      <Menu shadow="md" width={200} position="bottom" withinPortal>
        <Menu.Target>
          <div style={{ width: 'fit-content' }}>
            <PrimaryDropDown>Actions</PrimaryDropDown>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          {row?.status?.toLowerCase() === 'draft' ? (
            <>
              <Menu.Item
                icon={<IonIcon className="action-ion-icon" name="swap-horizontal-outline" />}
                onClick={() => {
                  submitFeePayment(row, dispatch);
                }}
              >
                Submit For Approval
              </Menu.Item>
            </>
          ) : null}

          {row?.status?.toLowerCase() === 'submitted' ? (
            <>
              <Menu.Item
                icon={<IonIcon className="action-ion-icon" name="swap-horizontal-outline" />}
                onClick={() => {
                  approveFeePayment(row, dispatch);
                }}
              >
                Approve
              </Menu.Item>
            </>
          ) : null}

          {row?.status?.toLowerCase() !== 'approved' ? (
            <>
              <Menu.Divider />
              <Menu.Label>Danger zone</Menu.Label>
            </>
          ) : null}

          {row?.status?.toLowerCase() === 'draft' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name="trash-outline" />}
              onClick={() => setShowDeleteModal(true)} // Show delete modal
            >
              Delete
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() === 'submitted' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name="swap-horizontal-outline" />}
              onClick={() => {
                rejectFeePayment(row, dispatch);
              }}
            >
              Reject
            </Menu.Item>
          ) : null}
        </Menu.Dropdown>
      </Menu>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <DeleteConfirmation
          onConfirm={handleDelete}
          onCancel={() => setShowDeleteModal(false)}
          message="Delete This Fee Payment?"
        />
      )}
    </div>
  );
}
