import { MasterTradeState } from './reducer'

export const getMasterTrades = (state: { masterTrades: MasterTradeState }) => state.masterTrades.masterTrades

export const getMasterTrade = (state: { masterTrades: MasterTradeState }) => {
  return state.masterTrades.masterTrade
}

export const getLockedMasterTrades = (state: { masterTrades: MasterTradeState }) => {
  return state.masterTrades.lockedMasterTrades
}

export const getMasterTradeLoader = (state: { masterTrades: MasterTradeState }) => {
  return state.masterTrades.isLoading
}

