import { PaymentParams } from "app/models/payments-params"


// get appropriate Success or Error Message depending on response success (boolean) and payment status
export const getPaymentMessage = (
    isCanceled: boolean | undefined,
    payment: PaymentParams,
    success: boolean
  ): { title: string; message: string } => {
    if (success) {
      if (isCanceled) {
        return {
          title: 'Successful Payment Cancelation',
          message: 'Payment has been successfully canceled',
        }       
      }
      if (payment.status === 'Queued') {
        if (payment.submitted) {
          return {
            title: 'Successful Payment Release',
            message: 'Payment has been successfully released',
          }
        }
        return {
          title: 'Successful Payment Submission',
          message: 'Payment has been successfully submitted for release',
        }
      }
      if (payment.status === 'Released') {
        return {
          title: 'Successful Payment Settlement',
          message: 'Payment has been successfully settled',
        }
      }
      return {
        title: 'Successful Payment Action',
        message: 'Payment action has been successfully processed',
      }
    } else {
      if (isCanceled) {
        return {
          title: 'Failed Payment Cancelation',
          message: 'Failed to cancel payment',
        }
      }
      if (payment.status === 'Queued') {
        if (payment.submitted) {
          return {
            title: 'Failed Payment Release',
            message: 'Failed to release payment',
          }
        }
        return {
          title: 'Failed Payment Submission',
          message: 'Failed to submit payment',
        }
      }
      if (payment.status === 'Released') {
        return {
          title: 'Failed Payment Settlement',
          message: 'Failed to settle payment',
        }
      }
      return {
        title: 'Payment Action Failed',
        message: 'Failed to process payment action',
      }
    }
  }