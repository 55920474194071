import { Group, Text, SelectItemProps } from '@mantine/core'
import '../../../../styles/components/multi-select-search-input.css'


interface OptionItemProps extends SelectItemProps {
    label: string
    value: string
    isHeader?: boolean
}

// Items that appear in the Dropdown of the MultiSelect - allows for a dropdown of single column of labels, or a dropdown with a label on left and right.
// If you want 2 columns of labels, provide label string with values separated by a pipe symbol ('|'), i.e. 'EntityName|Mei' - this will split the two values
// into 2 columns split with space-between styling.
const MultiSelectOptionItem = (({ label, isHeader, ...props }: OptionItemProps) => {
    const hasSplit = label.includes('|')
    const [labelLeft, labelRight] = hasSplit ? label.split('|') : [label, null]

    return (
        <Group
            w='100%'
            position='apart'
            className={`multi-select-option-item ${isHeader ? 'header' : ''}`}
            sx={{
                fontSize: '14px',
                padding: '10px',
                boxShadow: '0 1px 0 0 #cac9c9',
                borderLeft: '1px solid #cac9c9',
                borderRight: '1px solid #cac9c9',
                borderRadius: '0px',
                cursor: 'pointer',
                backgroundColor: isHeader ? '#f0eeee' : 'transparent', // For header items
                fontWeight: isHeader ? 'bold' : 'normal', // Bold font weight for header
                '&:hover': {
                    backgroundColor: isHeader ? '#f0eeee' : '#e3e7ff', // Different hover color based on header
                },
            }}
            {...props}
        >
            {/* Render labelLeft always, and conditionally render labelRight if it exists */}
            <Text className={`multi-select-option-text ${isHeader ? 'header' : ''}`} sx={{ color: isHeader ? '#784595' : '#476DB9' }}>{labelLeft}</Text>
            {labelRight && (
                <Text className={`multi-select-option-text ${isHeader ? 'header' : ''}`} sx={{ color: isHeader ? '#784595' : '#476DB9' }}>{labelRight}</Text>
            )}
        </Group >
    )
})

export default MultiSelectOptionItem;