import {
  Box,
  Group,
  Stack,
} from '@mantine/core'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getFacilities,
  getFacility,
} from 'app/state/ducks/facilities/selectors'
import {
  loadAllFacilities,
  setOneFacility,
} from 'app/state/ducks/facilities/thunks'
import { loadAllDeals, loadOneDeal } from 'app/state/ducks/deals/thunks'
import { getDeal, getDeals } from 'app/state/ducks/deals/selectors'
import { loadWireInstructions } from 'app/state/ducks/wire-instructions/thunks'
import { loadLendersPositionsByFacility } from 'app/state/ducks/lenders-positions/thunks'
import { DealParams } from 'app/models/deal-params'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { ContractType } from 'app/models/common-types'
import { FacilityParams } from 'app/models/facility-params'
import { getFacilityAmendments } from 'app/state/ducks/facility-amendments/selectors'
import { loadAllFacilityAmendments } from 'app/state/ducks/facility-amendments/thunks'
import { LetterOfCreditParams } from 'app/models/letter-of-credit'
import { loadAllLetterOfCredits } from 'app/state/ducks/letters-of-credits/thunks'
import { getLetterOfCredits, getLetterofCreditsLoader } from 'app/state/ducks/letters-of-credits/selectors'
import { loadIndexRateOptions } from 'app/state/ducks/index-rate-options/thunks'
import { loadNoteByContract } from 'app/state/ducks/notes/thunks'
import SectionTitle from 'app/views/components/Headers&Text/SectionTitle'
import NotesAndAttachment from 'app/views/components/notes-and-attachemnt/notes-and-attachment-full'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import DealDetails from 'app/views/components/deal/deal-detail'
import ExpandableMantineTable from './table-locs'

export default function FacilityLocContent(): JSX.Element {
  const dispatch = useDispatch()
  const { facilityId } = useParams()
  const [deal, setDeal] = useState<DealParams | undefined>(undefined)
  const lettersOfCreditLoader = useSelector(getLetterofCreditsLoader)
  const [globalLoading, setGlobalLoading] = useState(false)

  useEffect(() => {
    setGlobalLoading(lettersOfCreditLoader)
  }, [lettersOfCreditLoader])

  const letterOfCredits: LetterOfCreditParams[] | undefined =
    useSelector(getLetterOfCredits)
  const facility = useSelector(getFacility)
  const deals = useSelector(getDeals)
  const currentDeal = useSelector(getDeal)

  const allFacilities: FacilityParams[] = useSelector(getFacilities)
  const facilityAmendments: FacilityParams[] = useSelector(
    getFacilityAmendments
  )
  const entities = useSelector(getEntities)
  const entityIdToName = new Map<string, string>()
  const businessDate = useSelector(getBusinessDate)

  useEffect(() => {
    if (!facilityId) {
      return
    }
    if (allFacilities) {
      const selectedFacility = allFacilities.find(
        facility => facility.id === facilityId
      )
      if (selectedFacility) {
        selectedFacility.amendment = false
        dispatch(setOneFacility(selectedFacility))
      }
    }
    if (facilityAmendments) {
      const amended_facility = facilityAmendments.find(
        amendment => amendment.id === facilityId
      )
      if (amended_facility) {
        amended_facility.amendment = true
        dispatch(setOneFacility(amended_facility))
      }
    }
  }, [facilityId, facilityAmendments, allFacilities])

  useEffect(() => {
    if (!facilityId) {
      return
    }
    dispatch(loadAllLetterOfCredits(facilityId))
    dispatch(loadLendersPositionsByFacility(facilityId))
    dispatch(loadEntities())
    dispatch(loadAllFacilities())
    dispatch(loadWireInstructions())
    dispatch(loadAllDeals())
    dispatch(loadAllFacilityAmendments())
    dispatch(loadIndexRateOptions())
    dispatch(loadNoteByContract(facilityId, ContractType.Facilities))
  }, [facilityId])

  useEffect(() => {
    if (!facility || !deals) {
      return
    }
    const deal = deals.find(deal => deal.id === facility.dealId.id)
    if (deal) {
      setDeal(deal)
    } else {
      dispatch(loadOneDeal(facility.dealId.id, 'Terminated'))
    }
  }, [facility, deals])

  useEffect(() => {
    if (!currentDeal) {
      return
    }
    setDeal(currentDeal)
  }, [currentDeal])

  if (Array.isArray(entities)) {
    for (const entity of entities) {
      if (entity.id) {
        entityIdToName.set(entity.id, entity.entityName)
      }
    }
  }

  return (
    <Stack w="100%" align='stretch'>
      <Box style={{ marginBottom: '20px' }}>
        <ExpandableMantineTable title={'Letters of Credit Within Facility'} locs={letterOfCredits} globalLoading={globalLoading} />
      </Box>
      <Stack w="100%" className='sectionRemoveGap'>
        <SectionTitle>Deal Details</SectionTitle>
        <Stack w='100%' className='sectionRemoveGap sectionBody'>
          <DealDetails deal={deal}
            entityIdToName={entityIdToName}
            businessDate={businessDate} />
        </Stack>
      </Stack>
      <Group w="100%" noWrap mt='30px'>
        <NotesAndAttachment
          itemId={facility?.id ?? ''}
          type={ContractType.Facilities}
          itemAdmin={facility?.accountManagementAdmin ?? ''}
        />
      </Group>
    </Stack>
  )
}
