import {
    Group,
    Stack,
    Text
} from '@mantine/core'
import { Option } from 'app/models/dropdown-options'
import { EntityParams } from 'app/models/entity-params'
import { EntityTypeHierarchy } from 'app/models/entity-type-params'
import { getEntityProfiles } from 'app/state/ducks/entity-profile/selectors'
import { getEntityTypes } from 'app/state/ducks/entity-type/selectors'
import SectionTitle from 'app/views/components/Headers&Text/SectionTitle'
import { DetailSection } from 'app/views/components/detailSections/detail-section'
import { useSelector } from 'react-redux'

type Props = {
    entity: EntityParams
}

export default function EntityOverviewSection({ entity }: Props) {
    const entityProfiles = useSelector(getEntityProfiles)
    const entityTypes = useSelector(getEntityTypes)

    const entityType = entityTypes?.find(type => type.id === entity?.entityType?.id)
    const isFundManager = entityType?.hierarchy ? [EntityTypeHierarchy.HierarchyFundManager, EntityTypeHierarchy.HierarchyBoth].includes(entityType?.hierarchy) : false

    const entityTypeOptions: Option[] = entityTypes.map(type => ({
        label: type.name,
        value: type.id ?? '',
    }))

    console.log('entityTypeOptions', entityTypeOptions)

    const entityDetailsObjectArray = [
        {
            label: 'Entity Type',
            value: entityTypeOptions.find(option => option.value === entityType?.id)?.label || 'N/A'
        },
        {
            label: 'Entity Profile',
            value:
                entity?.entityProfile
                    .map(
                        profile =>
                            entityProfiles.find(ep => ep.id === profile.id)
                                ?.name || '',
                    )
                    .filter(Boolean)
                    .join(', ') ?? 'N/A',
        },
        {
            label: 'MEI',
            value: entity?.mei,
        },
        {
            label: 'GIIN',
            value: entity?.giin,
        },
        {
            label: isFundManager ? 'Entity Profile' : 'Parent',
            value: isFundManager ? entity?.fundManager : entity?.parent,
        },
        {
            label: isFundManager ? 'Fund Manager MEI' : 'Parent MEI',
            value: isFundManager ? entity?.fundManagerMei : entity?.parentMei,
        },
    ]

    const addressDetailsObjectArray = [
        { label: 'Address', value: entity?.address },
        { label: 'Address Line 2', value: entity?.address2 },
        { label: 'City', value: entity?.city },
        { label: 'State/Region', value: entity?.state },
        { label: 'ZIP/Postal Code', value: entity?.zipCode },
        {
            label: 'Country/Region',
            value: entity?.country,
        },
    ]

    return (
        <Stack w="100%" className='sectionRemoveGap' mt='30px'>
            <SectionTitle>Entity Overview</SectionTitle>
            <Stack w='100%' className='sectionRemoveGap sectionBody'>
                <Group w='100%' noWrap className='sectionRemoveGap' align='top'>
                    <Stack w='50%' className='sectionDetailBody'>
                        <Text className='sectionDetailHeader'>Entity Details</Text>
                        <DetailSection detailObjArr={entityDetailsObjectArray} columnCount={3} />
                    </Stack>
                    <Stack w='50%' className='sectionDetailBody'>
                        <Text className='sectionDetailHeader'>Address</Text>
                        <DetailSection detailObjArr={addressDetailsObjectArray} columnCount={3} />
                    </Stack>
                </Group>
            </Stack>
        </Stack>
    )
}