import { useForm } from '@mantine/form'
import {
  EntityParams
} from 'app/models/entity-params'
import { useState } from 'react'
import { saveEntity } from 'app/state/ducks/entities/thunks'
import { useDispatch, useSelector } from 'react-redux'
import config from 'app/config/config'
import { formatDateToUTC, isAlphanumeric, isNumeric, stringToDate } from 'app/utils/util-functions'
import { useNavigate } from 'react-router-dom'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { getEntityProfiles } from 'app/state/ducks/entity-profile/selectors'
import { getEntityTypes } from 'app/state/ducks/entity-type/selectors'
import { EntityTypeHierarchy } from 'app/models/entity-type-params'
import EntitiesFormPresenation from './entities-form-presentation'

type EntitiesFormProps = {
  entity?: EntityParams
  closeModal: () => void
  isStepper?: boolean
  onHandleSubmit?: any
  withRedirection?: boolean
}

const GIN_REGEX = /^[A-NP-Z0-9]{6}\.[A-NP-Z0-9]{5}\.(?:LE|SL|ME|BR|SP|SF|SD|SS|SB)\.\d{3}$/

export default function EntitiesForm({
  entity,
  closeModal,
  isStepper,
  onHandleSubmit,
  withRedirection,
}: EntitiesFormProps) {
  const [isLoading, setIsLoading] = useState(false)
  const entityTypes = useSelector(getEntityTypes)
  const entityProfiles = useSelector(getEntityProfiles)


  const navigate = useNavigate()

  const dispatch = useDispatch()
  const form = useForm({
    initialValues: {
      id: entity?.id ?? '',
      entityName: entity?.entityName ?? '',
      entityType: entity?.entityType?.id ?? null,
      entityProfile: entity?.entityProfile.map(profileKey => profileKey.id) ?? [],
      entityReference: entity?.entityReference ?? null,
      address: entity?.address ?? null,
      address2: entity?.address2 ?? null,
      city: entity?.city ?? null,
      state: entity?.state ?? null,
      zipCode: entity?.zipCode ?? null,
      country: entity?.country ?? null,
      kycType: entity?.kycType ?? '',
      kycStatus: entity?.kycStatus ?? '',
      institutionType: entity?.institutionType ?? '',
      countryOfIncorporation: entity?.countryOfIncorporation ?? null,
      countryOfTaxResidence: entity?.countryOfTaxResidence ?? null,
      taxFormStatus: entity?.taxFormStatus ?? null,
      usTaxFormType: entity?.usTaxFormType ?? null,
      fundManager: entity?.fundManager ?? null,
      fundManagerMei: entity?.fundManagerMei ?? null,
      parent: entity?.parent ?? null,
      parentMei: entity?.parentMei ?? null,
      giin: entity?.giin ?? null,
      crn: entity?.crn ?? null,
      naicCode: entity?.naicCode ?? null,
      internalId: entity?.internalId ?? null,
      legalEntityId: entity?.legalEntityId ?? null,
      mei: entity?.mei ?? null,
      ein: entity?.ein ?? null,
      cei: entity?.cei ?? null,
      ukTreatyPassportNumber: entity?.ukTreatyPassportNumber ?? null,
      status: entity?.status ?? '',
      ukTreatyPassportExpiryDate: entity?.ukTreatyPassportExpiryDate
        ? stringToDate(entity?.ukTreatyPassportExpiryDate)
        : null,
      entityParty: entity?.entityParty ?? null,
      hasError: entity?.hasError ?? false,
      ErrorMessage: entity?.ErrorMessage ?? '',
      hasSuccessfulEntitySave: entity?.hasSuccessfulEntitySave ?? false,
      customer_token: entity?.customer_token ?? config.company.toUpperCase(),

    },
    validate: {
      // Required fields for draft entity
      entityName: value => (!value.trim() ? 'Required' : null),
      kycStatus: value => (!value.trim() ? 'Required' : null),
      // Fields not required for draft entity, but validation rules are there if data is entered
      fundManagerMei: (value, values) => {
        if (values.entityType === undefined || values.entityType === null) {
          // If entityType is blank, don't validate fundManagerMei
          return null;
        }
        // If entityType is not in the list, apply validation
        return [EntityTypeHierarchy.HierarchyFundManager, EntityTypeHierarchy.HierarchyBoth].includes(
          entityTypes.find(type => type.id === values?.entityType)?.hierarchy as EntityTypeHierarchy
        )
          ? value && value.length !== 10
            ? '10 characters'
            : null
          : null;

      },
      parentMei: (value, values) => {
        if (values.entityType === undefined || values.entityType === null) {
          // If entityType is blank, don't validate parentMei
          return null;
        }

        // If entityType is not in the list, apply validation
        return [EntityTypeHierarchy.HierarchyParent, EntityTypeHierarchy.HierarchyBoth].includes(
          entityTypes.find(type => type.id === values?.entityType)?.hierarchy as EntityTypeHierarchy
        )
          ? value && value.length !== 10
            ? '10 characters'
            : null
          : null;
      },
      legalEntityId: value =>
        value && value !== '' && value.length !== 20 ? '20 characters' : null,
      giin: value =>
        value && !GIN_REGEX.test(value) ? 'GIIN must be in XXXXXX.XXXXX.XX.XXX format. (example: "AZ9123.AXCV0.LE.123")' : null,
      crn: value =>
        value && value !== '' && (value.length !== 8 || !isAlphanumeric(value)) ? '8 Alphanumeric Characters' : null,
      ein: value =>
        value && value !== '' && (value.length !== 9 || !isNumeric(value)) ? '9 Numeric Characters' : null,
      cei: value =>
        value && value !== '' && value.length !== 10 ? '10 characters' : null,
      naicCode: value =>
        value && value != '' && ((value.length < 2 || value.length > 6) || !isNumeric(value))
          ? 'Between 2 and 6 numeric characters'
          : null,
      mei: value =>
        value && (value == '' || value.length !== 10) ? 'MEI Should Be 10 Characters' : null,
      zipCode: value =>
        value && value !== '' && (!isAlphanumeric(value)) ? 'ZipCode must be Alphanumeric' : null,
    },
    transformValues: values => ({
      ...values,
      ukTreatyPassportExpiryDate:
        values.ukTreatyPassportExpiryDate === null
          ? null
          : formatDateToUTC(values.ukTreatyPassportExpiryDate),
      kycType: values.kycType === '' ? null : values.kycType,
      fundManagerMei:
        entityTypes
          .find(type => type.id === values?.entityType)
          ?.hierarchy &&
          [EntityTypeHierarchy.HierarchyFundManager, EntityTypeHierarchy.HierarchyBoth].includes(
            entityTypes.find(type => type.id === values?.entityType)?.hierarchy as EntityTypeHierarchy
          )
          ? values.fundManagerMei
          : null,
      fundManager:
        entityTypes
          .find(type => type.id === values?.entityType)
          ?.hierarchy &&
          [EntityTypeHierarchy.HierarchyFundManager, EntityTypeHierarchy.HierarchyBoth].includes(
            entityTypes.find(type => type.id === values?.entityType)?.hierarchy as EntityTypeHierarchy
          )
          ? values.fundManager
          : null,
      parentMei:
        entityTypes
          .find(type => type.id === values?.entityType)
          ?.hierarchy &&
          [EntityTypeHierarchy.HierarchyParent, EntityTypeHierarchy.HierarchyBoth].includes(
            entityTypes.find(type => type.id === values?.entityType)?.hierarchy as EntityTypeHierarchy
          )
          ? values.parentMei
          : null,
      parent:
        entityTypes
          .find(type => type.id === values?.entityType)
          ?.hierarchy &&
          [EntityTypeHierarchy.HierarchyParent, EntityTypeHierarchy.HierarchyBoth].includes(
            entityTypes.find(type => type.id === values?.entityType)?.hierarchy as EntityTypeHierarchy
          )
          ? values.parent
          : null,
      institutionType:
        values.institutionType === '' ? null : values.institutionType,
      ukTreatyPassportNumber:
        values.ukTreatyPassportNumber === ''
          ? null
          : values.ukTreatyPassportNumber,
      entityType: !values.entityType ? null : { id: entityTypes.find(type => type.id === values?.entityType)?.id ?? '', admin: entityTypes.find(type => type.id === values?.entityType)?.agencyAdmin ?? '' },
      entityProfile: values.entityProfile.map(
        profileKey => {
          const profile = entityProfiles.find(profile => profile.id === profileKey)
          return { id: profile?.id ?? '', admin: profile?.agencyAdmin ?? '' }
        }),
      hasError: false,
      hasSuccessfulEntitySave: false,
      ErrorMessage: '',
      customer_token: config.company.toUpperCase(),
    }),
    validateInputOnBlur: true,
  })

  async function onSubmit(entityParams: any) {
    const entityzz = form.getTransformedValues()
    console.log('entity', entityzz)
    try {
      setIsLoading(true)

      const response: any = await dispatch(saveEntity(entityParams, entity))

      if (response.success) {
        SuccessNotification({
          title: 'Successful Save Entity',
          message: 'You created or edited an entity',
        })
        if (withRedirection) {
          closeModal()
          navigate(
            `/entitymanagement/entity/${response?.payload?.data?.id}/Inactive`
          )
        }
        return response.payload
      } else {
        ErrorNotification({
          title: 'Entity save failed',
          message: response.payload ?? 'Check data and try again',
        })
      }
      return undefined
    } catch {
      ErrorNotification({
        title: 'Entity save failed',
        message: 'Check data and try again',
      })
    } finally {
      setIsLoading(false)
    }
  }

  async function submitForm(event: any) {
    event.preventDefault()
    if (!form.validate().hasErrors) {
      const entity = await onSubmit(form.getTransformedValues())
      onHandleSubmit(entity ?? form.values)
    } else {
      ErrorNotification({
        title: 'Form Validation Error',
        message: 'Please fill in required fields',
      });
    }
  }

  async function submitAndExit() {
    if (!form.validate().hasErrors) {
      withRedirection = true
      const entity = await onSubmit(form.getTransformedValues())
      onHandleSubmit(entity ?? form.values)
    } else {
      ErrorNotification({
        title: 'Form Validation Error',
        message: 'Please fill in required fields',
      });
    }
  }



  return (
    <EntitiesFormPresenation form={form} isLoading={isLoading} isStepper={isStepper} submitForm={submitForm} submitAndExit={submitAndExit} />
  )
}
