import { EntityTypes } from "app/models/entity-type-params";

export const roleLabels: { [key in EntityTypes]: string } = {
    [EntityTypes.EntityName]: 'Entity Name',
    [EntityTypes.MEI]: 'MEI (Market Entity Identifier)',
    [EntityTypes.LEI]: 'LEI',
    [EntityTypes.Address]: 'Address',
    [EntityTypes.UsTaxFormType]: 'US Tax Form Type',
    [EntityTypes.InstitutionType]: 'Institution Type',
    [EntityTypes.CEI]: 'CEI',
    [EntityTypes.TaxFormStatus]: 'Tax Form Status',
    [EntityTypes.AddressLine2]: 'Address Line 2',
    [EntityTypes.KycType]: 'KYC Type',
    [EntityTypes.NAICCode]: 'NAIC Code',
    [EntityTypes.KycStatus]: 'KYC Status',
    [EntityTypes.CountryRegion]: 'Country / Region',
    [EntityTypes.Parent]: 'Parent',
    [EntityTypes.CRN]: 'CRN',
    [EntityTypes.CountryOfIncorporation]: 'Country Of Incorporation',
    [EntityTypes.City]: 'City',
    [EntityTypes.ParentMEI]: 'Parent MEI',
    [EntityTypes.EIN]: 'EIN',
    [EntityTypes.CountryOfTaxResidence]: 'Country Of Tax Residence',
    [EntityTypes.State]: 'State',
    [EntityTypes.FundManagerName]: 'Fund Manager',
    [EntityTypes.GIIN]: 'GIIN',
    [EntityTypes.PostalCode]: 'ZIP / Postal Code',
    [EntityTypes.FundManagerMEI]: 'Fund Manager MEI',
    [EntityTypes.EntityReference]: 'GIIN',
};

export const groupedRoles = {
    'Entity Details': [
        EntityTypes.EntityName,
        EntityTypes.MEI,
        EntityTypes.InstitutionType,
        EntityTypes.KycType,
        EntityTypes.KycStatus,
        EntityTypes.Parent,
        EntityTypes.ParentMEI,
        EntityTypes.FundManagerName,
        EntityTypes.FundManagerMEI,
    ],
    'Address': [
        EntityTypes.Address,
        EntityTypes.AddressLine2,
        EntityTypes.CountryRegion,
        EntityTypes.City,
        EntityTypes.State,
        EntityTypes.PostalCode,
    ],
    'Identifiers': [
        EntityTypes.LEI,
        EntityTypes.CEI,
        EntityTypes.NAICCode,
        EntityTypes.CRN,
        EntityTypes.EIN,
        EntityTypes.GIIN,
    ],
    'Tax': [
        EntityTypes.EntityReference,
        EntityTypes.UsTaxFormType,
        EntityTypes.TaxFormStatus,
        EntityTypes.CountryOfIncorporation,
        EntityTypes.CountryOfTaxResidence,
    ],
};