/* eslint-disable @typescript-eslint/no-unused-vars */
import { Menu } from '@mantine/core';
import PrimaryDropDown from 'app/views/components/DropDown/DropDown';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    deleteContacts,
    setActiveContact,
    makeInactiveContact,
} from './contacts-daml-actions';
import { ContactParams } from 'app/models/contact-params';
import IonIcon from '@reacticons/ionicons';
import { ContactsModal } from './contacts-modal';
import DeleteConfirmation from 'app/views/components/delete-confirmation/DeleteConfirmation';

type Props = {
    row: ContactParams;
};

export default function ContactActions({ row }: Props) {
    const dispatch = useDispatch();
    const [addContacts, setAddContacts] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false); // Delete confirmation modal state

    function editContact() {
        setAddContacts(true);
    }

    const handleDelete = () => {
        deleteContacts(row, dispatch); // Trigger the delete action
        setShowDeleteModal(false); // Close the modal
    };

    return (
        <div className="ContactActions">
            <ContactsModal
                open={addContacts}
                setOpen={setAddContacts}
                contact={row}
                entityId={row.entity.id ?? ''}
            />
            <Menu shadow="md" width={200} position="bottom" withinPortal>
                <Menu.Target>
                    <div style={{ width: 'fit-content' }}>
                        <PrimaryDropDown>Actions</PrimaryDropDown>
                    </div>
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Item
                        icon={<IonIcon className="action-ion-icon" name="settings-outline" />}
                        onClick={() => editContact()}
                    >
                        Edit
                    </Menu.Item>

                    {row?.status?.toLowerCase() == 'inactive' ? (
                        <Menu.Item
                            icon={<IonIcon className="action-ion-icon" name="swap-horizontal-outline" />}
                            onClick={() => {
                                setActiveContact(row, dispatch);
                            }}
                        >
                            Activate
                        </Menu.Item>
                    ) : (
                        <Menu.Item
                            icon={<IonIcon className="action-ion-icon" name="swap-horizontal-outline" />}
                            onClick={() => {
                                makeInactiveContact(row, dispatch);
                            }}
                        >
                            Deactivate
                        </Menu.Item>
                    )}

                    {row?.status?.toLowerCase() == 'inactive' ? (
                        <Menu.Item
                            color="red"
                            icon={<IonIcon className="action-ion-icon" name="trash-outline" />}
                            onClick={() => setShowDeleteModal(true)} // Show confirmation modal
                        >
                            Delete
                        </Menu.Item>
                    ) : null}
                </Menu.Dropdown>
            </Menu>

            {/* Delete Confirmation Modal */}
            {showDeleteModal && (
                <DeleteConfirmation
                    message='Delete This Contact?'
                    onConfirm={handleDelete} // Call delete action on confirmation
                    onCancel={() => setShowDeleteModal(false)} // Close modal on cancel
                />
            )}
        </div>
    );
}
