import { Button, Group, Modal, Stack } from '@mantine/core'
import { useEffect, useState } from 'react'
import EntitiesForm from './entities-form-logic'
import ContactsForm from './entity-contacts/contacts-form'
import WireInstructionsForm from './entity-wire-instructions/wire-instructions-form'
import { useMultiStepForm } from 'app/hooks/useMultiStepForm'
import { EntityParams } from 'app/models/entity-params'
import IonIcon from '@reacticons/ionicons'
import { ContactParams } from 'app/models/contact-params'
import { WireInstructionParams } from 'app/models/wire-instruction-params'
import { useNavigate } from 'react-router-dom'

type MultiPartFormProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  entityToEdit?: EntityParams
  contactsToEdit?: ContactParams[]
  wireInstructionsToEdit?: WireInstructionParams[]
}

// Enum does not have strings assigned to each tab to handle as a step number to make use of the hook useMultiStepForm
enum Tab {
  entity,
  contact,
  wireInstructions,
}

function getTabStyles(tab: Tab, Tab: Tab) {
  return {
    root: {
      border: '1px solid #D1D5DB !important',
      backgroundColor:
        tab === Tab ? '#EBF5FF !important' : '#F9FAFB !important',
      height: '44px !important',
      borderRadius: '5px !important',
      opacity: tab === Tab ? 1 : 0.6,
    },
    label: {
      color: tab === Tab ? '#1A56DB !important' : '#1F2A37',
      fontSize: '14px !important',
      lineHeight: '150% !important',
      fontStyle: 'normal !important',
      fontWeight: 500,
      width: '100% !important',
    },
  }
}

export default function MultiPartForm({ open, setOpen, entityToEdit, contactsToEdit, wireInstructionsToEdit }: MultiPartFormProps) {
  const ArrowIcon = () => <IonIcon name="arrow-forward-outline" style={{ color: '#1A56DB' }} />
  const CheckIcon = () => <IonIcon name="checkmark-outline" style={{ color: '#046C4E' }} />

  const [tab, setTab] = useState<Tab>(Tab.entity)
  const [entityId, setEntityId] = useState<string>('')
  const [entity, setEntity] = useState<EntityParams | undefined>(undefined)
  const [submitAction, setSubmitAction] = useState<string | undefined>(undefined);
  const navigate = useNavigate()

  // determine action based on which button was clicked in EntitiesForm ("Save" or "Save and Proceed")
  function handleSubmit() {
    if (submitAction === 'saveAndSubmit') {
      handleSaveAndSubmit()
    } else {
      handleSaveAndProceed(currentStepIndex)
    }
  }

  // Save and Proceed throught stepper for adding Contacts and Wire Instructions
  function handleSaveAndProceed(tab: number) {
    const moveOneStep = tab + 1
    if (!isLastStep) {
      setSubmitAction(undefined)
      next()
      setTab(moveOneStep)
    } else {
      navigate(`/entitymanagement/entity/${entity?.id}/inactive`)
      setOpen(false)
    }
  }

  // Saves and submits the edited Entity Form without entering the stepper for Contacts and Wire Instructions
  function handleSaveAndSubmit() {
    navigate(`/entitymanagement/entity/${entity?.id}/inactive`)
    setOpen(false)
    setSubmitAction(undefined)
  }

  function handleEntitySubmit(formData: { data: EntityParams }) {
    setEntity(formData?.data)
    setEntityId(formData?.data?.id ?? '')
    // handle submit will determine if the form should proceed to the next step or submit the form based on the 
    // submitAction which is determined by the button clicked in EntitiesForm
    handleSubmit()
  }

  // Hook to handle multiple steps, every step is controlled depending on the Form you are positioned.
  const {
    step,
    isFirstStep,
    isLastStep,
    currentStepIndex,
    goTo,
    /*back,*/ next,
  } = useMultiStepForm([
    <EntitiesForm
      isStepper={true}
      entity={entity ?? entityToEdit}
      onHandleSubmit={handleEntitySubmit}
      closeModal={() => setOpen(!open)}
    />,
    <ContactsForm
      entityId={entityId}
      isStepper={true}
      onHandleSubmit={handleSubmit}
      multipleContacts={contactsToEdit}
    />,
    <WireInstructionsForm
      entityId={entityId}
      isStepper={true}
      onHandleSubmit={handleSubmit}
      multipleWireInstructions={wireInstructionsToEdit}
      closeModal={() => null}
    />,
  ])

  // EveryTime the form open it resets to the first step
  useEffect(() => {
    if (!isFirstStep) {
      goTo(Tab.entity)
      setTab(Tab.entity)
    }
  }, [open])

  return (
    <Modal
      className="modal-body create-new-form fit-content-modal"
      opened={open}
      onClose={() => setOpen(!open)}
      centered={true}
      size="77%"
    >
      <Group
        className="stepper-modal"
        noWrap
        position="apart"
        w="100%"
        h="100%"
        align="flex-start"
      >
        <Stack w="25%" h="100%" className="form-wrapper">
          <Button
            rightIcon={
              currentStepIndex > Tab.entity ? <CheckIcon /> : <ArrowIcon />
            }
            styles={getTabStyles(tab, Tab.entity)}
            onClick={() => {
              setTab(Tab.entity)
              goTo(Tab.entity)
            }}
            className="button-stepper"
          >
            1. Entity
          </Button>
          <Button
            rightIcon={
              currentStepIndex > Tab.contact ? <CheckIcon /> : <ArrowIcon />
            }
            styles={getTabStyles(tab, Tab.contact)}
            onClick={() => {
              setTab(Tab.contact)
              goTo(Tab.contact)
            }}
            data-disabled
          >
            2. Contacts
          </Button>
          <Button
            rightIcon={
              currentStepIndex > Tab.wireInstructions ? (
                <CheckIcon />
              ) : (
                <ArrowIcon />
              )
            }
            styles={getTabStyles(tab, Tab.wireInstructions)}
            onClick={() => {
              setTab(Tab.wireInstructions)
              goTo(Tab.wireInstructions)
            }}
            data-disabled
          >
            3. Wire Instructions
          </Button>
        </Stack>
        <div className="divider"></div>
        <Stack w="70%" className="form-wrapper">
          {tab === Tab.entity && <>{step}</>}
          {tab === Tab.contact && <>{step}</>}
          {tab === Tab.wireInstructions && <>{step}</>}
        </Stack>
      </Group>
    </Modal>
  )
}