import { MRT_ColumnDef } from 'mantine-react-table';
import { Text } from '@mantine/core';
import { formatNumberToCurrency } from 'app/utils/util-functions';
import { MasterTradeParams } from 'app/models/master-trade-params';

const getTextStyle = (color: string, weight = 600) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: weight,
    lineHeight: '19px',
    textAlign: 'left' as React.CSSProperties['textAlign'],
    color,
});

function TableHead({ header }: { header: string }) {
    return (
        <Text style={getTextStyle('#784595', 400)}>
            {header}
        </Text>
    );
}

export const columnDefs: MRT_ColumnDef<MasterTradeParams>[] = [
    {
        size: 50,
        accessorKey: 'status',
        header: 'Status',
        Header: <TableHead header={'Status'} />,
        Cell: ({ cell }) => {
            const status = (cell.getValue() as string ?? '').toLowerCase();
            const statusColors: { [key: string]: string } = {
                open: '#287A26',
                settled: '#EE5959',
                draft: '#757373',
            };

            return (
                <Text style={getTextStyle(statusColors[status] || '#000000')}>
                    {cell.getValue() as string ?? ''}
                </Text>
            );
        },
    },
    {
        size: 80,
        header: 'Cell 1',
        Header: () => (
            <div>
                <TableHead header={'SubtTrade ID'} />
                <TableHead header={'Internal ID'} />
            </div>
        ),
        Cell: () => {
            const tradeId = '173536';
            const clientId = '001';
            return (
                <div>
                    <Text style={{ ...getTextStyle('#476DB9'), textDecoration: 'underline' }}>
                        {tradeId}
                    </Text>
                    <Text style={getTextStyle('#476DB9')}>
                        {clientId}
                    </Text>
                </div>
            );
        },
    },
    {
        size: 60,
        header: 'Cell 2',
        Header: () => (
            <div>
                <TableHead header={'Settlement Date'} />
                <TableHead header={'T+'} />
            </div>
        ),
        Cell: () => {
            return (
                <div>
                    <Text style={getTextStyle('#476DB9')}>2014-12-26</Text>
                    <Text style={getTextStyle('#757373')}>10</Text>
                </div>
            );
        },
    },
    {
        size: 60,
        header: 'Cell 4',
        Header: () => (
            <div>
                <TableHead header={'My Entity'} />
                <TableHead header={'MEI'} />
            </div>
        ),
        Cell: ({ row }) => {
            const entity = row.original.myEntity === 'Buy' ? row.original.buyerEntity : row.original.sellerEntity;
            const entityName = entity?.entityName ?? 'Unknown';
            const entityMei = entity?.mei ?? 'N/A';

            return (
                <div>
                    <Text style={getTextStyle('#476DB9')}>
                        {entityName}
                    </Text>
                    <Text style={getTextStyle('#757373')}>{entityMei}</Text>
                </div>
            );
        },
    },
    {
        size: 60,
        header: 'Cell 5',
        Header: () => (
            <div>
                <TableHead header={'Counterparty'} />
                <TableHead header={'MEI'} />
            </div>
        ),
        Cell: ({ row }) => {
            const entity = row.original.myEntity === 'Sell' ? row.original.buyerEntity : row.original.sellerEntity;
            const entityName = entity?.entityName ?? 'Unknown';
            const entityMei = entity?.mei ?? 'N/A';

            return (
                <div>
                    <Text style={getTextStyle('#476DB9')}>
                        {entityName}
                    </Text>
                    <Text style={getTextStyle('#757373')}>{entityMei}</Text>
                </div>
            );
        },
    },
    {
        size: 80,
        header: 'Cell 6',
        Header: () => (
            <div>
                <TableHead header={'Facilities'} />
            </div>
        ),
        Cell: ({ row }) => {
            const refinedFacility = row.original.tradedFacilities?.[0]?.facilityDetails;

            return (
                <div>
                    <Text style={getTextStyle('#476DB9')}>
                        {refinedFacility?.name ?? 'N/A'}
                    </Text>
                    <Text style={getTextStyle('#476DB9')}>
                        {refinedFacility?.facilityType ?? 'N/A'}
                    </Text>
                </div>
            );
        },
    },
    {
        size: 30,
        header: 'Cell 7',
        Header: () => (
            <div>
                <TableHead header={'Traded Amount'} />
            </div>
        ),
        Cell: ({ row }) => {
            const tradedFacilities = row.original.tradedFacilities ?? [];
            const baseCurrency = tradedFacilities[0]?.facilityDetails?.baseCurrency ?? 'USD';
            const sum = formatNumberToCurrency(
                tradedFacilities.reduce((acc, curr) => acc + Number(curr.tradeAmount), 0),
                baseCurrency
            );
            return <div>
                <Text style={getTextStyle('#476DB9')}>
                    {sum}
                </Text>
            </div>
        },
    },
    {
        size: 30,
        header: 'Cell 8',
        Header: () => (
            <div>
                <TableHead header={'Current Amount'} />
            </div>
        ),
        Cell: ({ row }) => {
            const tradedFacilities = row.original.tradedFacilities ?? [];
            const baseCurrency = tradedFacilities[0]?.facilityDetails?.baseCurrency ?? 'USD';
            const sum = formatNumberToCurrency(
                tradedFacilities.reduce((acc, curr) => acc + Number(curr.currentAmount), 0),
                baseCurrency
            );
            return <div>
                <Text style={getTextStyle('#476DB9')}>
                    {sum}
                </Text>
            </div>
        },
    },
    {
        size: 30,
        header: 'Cell 9',
        Header: () => (
            <div>
                <TableHead header={'Price'} />
            </div>
        ),
        Cell: ({ row }) => {
            const uniquePrices = Array.from(new Set(row.original.tradedFacilities.map(f => f.price)));
            return (
                <div>
                    {uniquePrices.map((price, index) => (
                        <Text key={index} style={getTextStyle('#476DB9')}>
                            {price}
                        </Text>
                    ))}
                </div>
            );
        },
    },
];
