import { Menu, Modal } from '@mantine/core';
import PrimaryDropDown from 'app/views/components/DropDown/DropDown';
import { useDispatch, useSelector } from 'react-redux';
import { LoanConversionParams } from 'app/models/loan-conversion-params';
import {
  approveLoanConversion,
  deleteLoanConversion,
  rejectLoanConversion,
  submitLoanConversion,
} from './conversion-daml-actions';
import { useState } from 'react';
import { getAllApprovedLoans } from 'app/state/ducks/loans/selectors';
import ConversionFormLogic from './conversion-form-logic';
import IonIcon from '@reacticons/ionicons';
import { LoanParams } from 'app/models/loan-params';
import DeleteConfirmation from 'app/views/components/delete-confirmation/DeleteConfirmation'; // Updated import

type Props = {
  row: LoanConversionParams;
};

export default function LoanConversionActions({ row }: Props) {
  const allLoans = useSelector(getAllApprovedLoans);
  const [loan, setLoan] = useState<LoanParams>();
  const [openNewConversion, setOpenNewConversion] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false); // State for delete modal
  const dispatch = useDispatch();

  function openConversion() {
    setLoan(allLoans.find((l) => l.id === row.loanId.id));
    setOpenNewConversion(true);
  }

  const handleDelete = () => {
    deleteLoanConversion(row, dispatch); // Perform the delete action
    setShowDeleteModal(false); // Close the modal after deletion
  };

  return (
    <div>
      <Modal
        size="50%"
        className="modal-body modal-body-template fit-content-modal"
        opened={openNewConversion}
        onClose={() => setOpenNewConversion(false)}
      >
        {loan ? (
          <ConversionFormLogic loan={loan} close={() => setOpenNewConversion(false)} />
        ) : null}
      </Modal>
      <Menu shadow="md" width={200} position="bottom" withinPortal>
        <Menu.Target>
          <div style={{ width: 'fit-content' }}>
            <PrimaryDropDown>Actions</PrimaryDropDown>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          {row?.status?.toLowerCase() === 'draft' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name="swap-horizontal-outline" />}
              onClick={() => openConversion()}
            >
              Edit
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() === 'draft' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name="swap-horizontal-outline" />}
              onClick={() => {
                submitLoanConversion(row, dispatch);
              }}
            >
              Submit For Approval
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() === 'submitted' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name="swap-horizontal-outline" />}
              onClick={() => {
                approveLoanConversion(row, dispatch);
              }}
            >
              Approve
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() === 'approved' ? (
            <Menu.Item icon={<IonIcon className="action-ion-icon" name="open-outline" />}>
              Amendment
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() !== 'approved' ? (
            <>
              <Menu.Divider />
              <Menu.Label>Danger zone</Menu.Label>
            </>
          ) : null}

          {row?.status?.toLowerCase() === 'draft' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name="trash-outline" />}
              onClick={() => setShowDeleteModal(true)} // Show delete confirmation modal
            >
              Delete
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() === 'submitted' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name="swap-horizontal-outline" />}
              onClick={() => {
                rejectLoanConversion(row, dispatch);
              }}
            >
              Reject
            </Menu.Item>
          ) : null}
        </Menu.Dropdown>
      </Menu>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <DeleteConfirmation
          onConfirm={handleDelete} // Handle delete action
          onCancel={() => setShowDeleteModal(false)} // Close modal on cancel
          message="Delete This Loan Conversion?" // Custom message
        />
      )}
    </div>
  );
}
