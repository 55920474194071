import { Menu, Modal } from '@mantine/core'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import { useEffect, useState } from 'react'
import { DealParams } from 'app/models/deal-params'
import { useDispatch, useSelector } from 'react-redux'
import { FacilityParams } from 'app/models/facility-params'
import { UpdatedFacilitiesScreen } from '../updated-facilities-screen'
import {
    approveFacility,
    approveFacilityAmendment,
    deleteFacility,
    deleteFacilityAmendment,
    rejectFacility,
    rejectFacilityAmendment,
    submitFacility,
    submitFacilityAmendment,
} from './facility-daml-actions'
import { loadLendersPositionsByFacility } from 'app/state/ducks/lenders-positions/thunks'
import { getDeals } from 'app/state/ducks/deals/selectors'
import HistoryForm from './history/history'
import { getFacilityAmendments } from 'app/state/ducks/facility-amendments/selectors'
import { useNavigate } from 'react-router-dom'
import { loadFeesByFacility } from 'app/state/ducks/fees/thunks'
import LetterOfCreditForm from '../../letter-of-credit/letter-of-credit-form-logic'
import { loadSchedulerAmortizationByFacility } from 'app/state/ducks/amortization/thunks'
import { UpdatedLoanScreen } from '../../loans/loan-stepper'
import IonIcon from '@reacticons/ionicons'
import FacilityTerminationForm from '../locked-facilities/facility-termination-form'
import { loadFacilitySnapshot } from 'app/state/ducks/facilities/thunks'
import { generateHTML } from '../../deals/preview/generateHTML'
import { formatDateSnapshot } from 'app/utils/util-functions'
import PrimaryInput from 'app/views/components/buttons/PrimaryInput'
import DeleteConfirmation from 'app/views/components/delete-confirmation/DeleteConfirmation'

type Props = {
    row: FacilityParams
    withRedirection?: boolean
    extended?: boolean
    primaryInput?: boolean
}

export default function FacilityActions({
    row,
    withRedirection,
    extended,
    primaryInput,
}: Props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [deleteType, setDeleteType] = useState<'facility' | 'amendment' | null>(null);
    const [openEdit, setOpenEdit] = useState(false)
    const [openTerminate, setOpenTerminate] = useState(false)
    const [facility, setFacility] = useState<FacilityParams | undefined>(row)
    const [deal, setDeal] = useState<DealParams | undefined>(undefined)
    const [openHistory, setOpenHistory] = useState(false)
    const [loanOpen, setLoanOpen] = useState(false)
    const [letterOfCreditOpen, setLetterOfCreditOpen] = useState(false)
    const [facilityAmendment, setFacilityAmendment] =
        useState<FacilityParams | null>(null)
    const [amendment, setAmendment] = useState<string | null>(null)
    const facilityAmendments: FacilityParams[] = useSelector(
        getFacilityAmendments
    )
    const [step, setStep] = useState<number>(0)
    const deals: DealParams[] = useSelector(getDeals)


    useEffect(() => {
        if (facility?.dealId?.id) {
            setDeal(deals.find(f => f.id == facility.dealId.id))
        }
    }, [facility])

    useEffect(() => {
        setFacility(row);
    }, [row])

    function editFacility(facilityId: string, step?: number): any {
        dispatch(loadLendersPositionsByFacility(facilityId))
        dispatch(loadFeesByFacility(facilityId))
        dispatch(loadSchedulerAmortizationByFacility(facilityId))
        setAmendment(null)
        setStep(step ?? 0)
        setOpenEdit(true)
    }

    function amendFacility(facilityId: string, step?: number) {
        dispatch(loadLendersPositionsByFacility(facilityId))
        dispatch(loadFeesByFacility(facilityId))
        dispatch(loadSchedulerAmortizationByFacility(facilityId))
        const amended_facility = facilityAmendments.find(
            amendment => amendment.id === facilityId
        )
        setFacilityAmendment(amended_facility ?? null)
        setAmendment('amend')
        setStep(step ?? 0)
        setOpenEdit(true)
    }

    function terminateFacility(facilityId: string) {
        const amended_facility = facilityAmendments.find(
            amendment => amendment.id === facilityId
        )
        setFacilityAmendment(amended_facility ?? null)
        setOpenTerminate(true)
    }

    function displayHistory(): any {
        setOpenHistory(true)
    }

    function openLoanForm() {
        setLoanOpen(true)
    }

    async function submitCurrentFacility() {
        const result = await submitFacility(row, dispatch)
        if (result && withRedirection) {
            navigate(`/dealmanagement/facilities/${row.id}/Submitted`)
        }
    }

    async function approveCurrentFacility() {
        const result = await approveFacility(row, dispatch)
        if (result && withRedirection) {
            navigate(`/dealmanagement/facilities/${row.id}/Approved`)
        }
    }

    async function rejectCurrentFacility() {
        const result = await rejectFacility(row, dispatch)
        if (result && withRedirection) {
            navigate(`/dealmanagement/facilities/${row.id}/Draft`)
        }
    }
    async function submitCurrentFacilityAmendment() {
        const result = await submitFacilityAmendment(row, dispatch)
        if (result && withRedirection) {
            navigate(`/dealmanagement/facilities/${row.id}/Subbmitted`)
        }
    }
    async function approveCurrentFacilityAmendment() {
        const result = await approveFacilityAmendment(row, dispatch)
        if (result && withRedirection) {
            navigate(`/dealmanagement/facilities/${row.id}/Approved`)
        }
    }
    async function rejectCurrentFacilityAmendment() {
        const result = await rejectFacilityAmendment(row, dispatch)
        if (result && withRedirection) {
            navigate(`/dealmanagement/facilities/${row.id}/Draft`)
        }
    }

    async function openAmortizationSchedule() {
        navigate(`/dealmanagement/facilities/${row.id}/${row.status}/amortization-schedule`)
    }

    const handleDelete = async () => {
        if (deleteType === 'facility') {
            await deleteFacility(row, dispatch);
            if (withRedirection) {
                navigate(`/dealmanagement/facilities`);
            }
        } else if (deleteType === 'amendment') {
            await deleteFacilityAmendment(row, dispatch);
            if (withRedirection) {
                navigate(`/dealmanagement/facilities/${row.id}/Approved`);
            }
        }
        setShowDeleteModal(false);
    };



    const openPreview = async (row: any) => {
        const facilitySnapshot = await loadFacilitySnapshot(row.id)
        row = facilitySnapshot.payload
        const { monthDayYear } = formatDateSnapshot();
        let previewHTML = ""
        previewHTML = `
    <!DOCTYPE html>
      <html>
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Facility</title>
            <link rel="stylesheet" type="text/css" href="/previewStyles.css" />
        </head>
        <body>
            <div id="content">
                <header>
                    <div>
                        <h1>Facility Snapshot</h1>
                    </div>
                    <div>As of ${monthDayYear}</div>
                </header>
    
                <main>
                    ${generateHTML(row, 1)}
                </main>
            </div>
        </body>
      </html>`;

        const newWindow = window.open("", "_blank")
        if (newWindow) {
            newWindow.document.write(previewHTML)
            newWindow.document.close()
        }
    }

    return (
        <div>
            <Modal
                className="modal-body modal-body-template fit-content-modal"
                opened={openEdit}
                onClose={() => setOpenEdit(false)}
                centered={true}
                size="77%"
            >
                {amendment ? (
                    <UpdatedFacilitiesScreen
                        deal={deal}
                        facility={facilityAmendment ?? facility}
                        step={step}
                        onClose={() => setOpenEdit(false)}
                        amendment={amendment}
                    />
                ) : (
                    <UpdatedFacilitiesScreen
                        deal={deal}
                        facility={facility}
                        step={step}
                        onClose={() => setOpenEdit(false)}
                    />
                )}
            </Modal>
            <Modal
                className="modal-body modal-body-template fit-content-modal"
                size="xl"
                // style={{ height: '80%' }}
                opened={openTerminate}
                onClose={() => setOpenTerminate(false)}
            >
                {facility && (
                    <FacilityTerminationForm
                        facility={facilityAmendment ?? facility}
                        close={() => setOpenTerminate(false)}
                    />
                )}
            </Modal>
            <Menu shadow="md" width={primaryInput ? '250px' : 200} position="bottom" withinPortal>
                <Menu.Target>
                    <div style={{ width: 'fit-content' }}>
                        {primaryInput ?
                            <PrimaryInput w='159px'>
                                Actions
                                <IonIcon name='chevron-down-outline' className='primaryInputChevronDown' />
                            </PrimaryInput>
                            :
                            <PrimaryDropDown>Actions</PrimaryDropDown>
                        }
                    </div>
                </Menu.Target>


                <Menu.Dropdown>
                    {row?.status?.toLowerCase() == 'approved' ? (
                        <Menu.Item
                            icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                            onClick={() => openPreview(row)}
                        >
                            Snapshot
                        </Menu.Item>
                    ) : null}
                    {row?.status?.toLowerCase() == 'draft' && !row?.amendment ? (
                        <Menu.Item
                            icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                            onClick={() => editFacility(row.id)}
                        >
                            Edit
                        </Menu.Item>
                    ) : null}
                    {row?.amortizationScheduleId ? (
                        <Menu.Item
                            icon={<IonIcon className="action-ion-icon" name='calendar-outline' />}
                            onClick={openAmortizationSchedule}
                        >
                            Schedule
                        </Menu.Item>
                    ) : null}

                    {row?.status?.toLowerCase() == 'draft' && row?.amendment ? (
                        row?.amendmentType?.toLowerCase() === 'termination' ? (
                            <Menu.Item
                                icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                                onClick={() => {
                                    terminateFacility(row.id)
                                }}
                            >
                                Terminate
                            </Menu.Item>
                        ) : (
                            <Menu.Item
                                icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                                onClick={() => {
                                    amendFacility(row.id)
                                }}
                            >
                                Amendment
                            </Menu.Item>
                        )
                    ) : null}

                    {row?.status?.toLowerCase() == 'draft' && row?.amendment ? (
                        <Menu.Item
                            icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                            onClick={() => submitCurrentFacilityAmendment()}
                        >
                            Submit For Approval
                        </Menu.Item>
                    ) : null}

                    {row?.status?.toLowerCase() == 'draft' && !row?.amendment ? (
                        <Menu.Item
                            icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                            onClick={() => submitCurrentFacility()}
                        >
                            Submit For Approval
                        </Menu.Item>
                    ) : null}

                    {row?.status?.toLowerCase() == 'submitted' && row?.amendment ? (
                        <Menu.Item
                            icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                            onClick={() => approveCurrentFacilityAmendment()}
                        >
                            Approve
                        </Menu.Item>
                    ) : null}

                    {row?.status?.toLowerCase() == 'submitted' && !row?.amendment ? (
                        <Menu.Item
                            icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                            onClick={() => approveCurrentFacility()}
                        >
                            Approve
                        </Menu.Item>
                    ) : null}

                    {row?.status?.toLowerCase() == 'approved' ||
                        row?.amendment?.status?.toLowerCase() === '' ? (
                        <>
                            <Menu.Item
                                icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                                onClick={() => {
                                    amendFacility(row.id)
                                }}
                            >
                                Amendment
                            </Menu.Item>
                            <Menu.Item
                                icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                                onClick={() => {
                                    terminateFacility(row.id)
                                }}
                            >
                                Terminate
                            </Menu.Item>
                        </>
                    ) : null}

                    {extended ? (
                        <>
                            {row?.status?.toLowerCase() == 'approved' ||
                                row?.amendment?.status?.toLowerCase() === '' ? (
                                <>
                                    <Menu.Item
                                        icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                                        onClick={() => openLoanForm()}
                                    >
                                        Create Loan
                                    </Menu.Item>
                                    <Menu.Item
                                        icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                                        onClick={() => setLetterOfCreditOpen(true)}
                                    >
                                        Create Letter of Credit
                                    </Menu.Item>
                                </>
                            ) : null}
                        </>
                    ) : null}

                    {row?.status?.toLowerCase() != 'approved' ? (
                        <>
                            <Menu.Divider />
                            <Menu.Label>Danger zone</Menu.Label>
                        </>
                    ) : null}

                    {row?.status?.toLowerCase() === 'draft' && !row?.amendment ? (
                        <Menu.Item
                            icon={<IonIcon className="action-ion-icon" name="settings-outline" />}
                            onClick={() => {
                                setShowDeleteModal(true);
                                setDeleteType('facility');
                            }}
                        >
                            Delete
                        </Menu.Item>
                    ) : null}

                    {row?.status?.toLowerCase() === 'draft' && row?.amendment ? (
                        <Menu.Item
                            icon={<IonIcon className="action-ion-icon" name="trash-outline" />}
                            color="red"
                            onClick={() => {
                                setShowDeleteModal(true);
                                setDeleteType('amendment');
                            }}
                        >
                            Delete
                        </Menu.Item>
                    ) : null}

                    {row?.status?.toLowerCase() == 'submitted' && row?.amendment ? (
                        <Menu.Item
                            color="red"
                            icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                            onClick={() => rejectCurrentFacilityAmendment()}
                        >
                            Reject
                        </Menu.Item>
                    ) : null}

                    {row?.status?.toLowerCase() == 'submitted' && !row?.amendment ? (
                        <Menu.Item
                            color="red"
                            icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                            onClick={() => rejectCurrentFacility()}
                        >
                            Reject
                        </Menu.Item>
                    ) : null}

                    <Menu.Item
                        onClick={() => displayHistory()}
                        icon={<IonIcon className="action-ion-icon" name='arrow-undo-circle-outline' />}
                    >
                        History
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
            <Modal
                size="90%"
                opened={openHistory}
                onClose={() => setOpenHistory(false)}
            >
                {facility && (
                    <HistoryForm
                        facilityId={facility.id ?? ''}
                        close={() => setOpenHistory(false)}
                    />
                )}
            </Modal>
            <>
                <Modal
                    className="modal-body modal-body-template fit-content-modal"
                    opened={loanOpen}
                    onClose={() => setLoanOpen(false)}
                    centered={true}
                    size="77%"
                >
                    <UpdatedLoanScreen
                        loan={undefined}
                        facility={facility}
                        step={0}
                        onClose={() => setLoanOpen(false)}
                    />
                </Modal>

            </>
            <>
                <Modal
                    className="modal-body modal-body-template fit-content-modal"
                    size="xl"
                    opened={letterOfCreditOpen}
                    onClose={() => setLetterOfCreditOpen(false)}
                >
                    <LetterOfCreditForm close={() => setLetterOfCreditOpen(false)} />
                </Modal>
            </>
            {/* Delete Confirmation Modal */}
            {showDeleteModal && (
                <DeleteConfirmation
                    onConfirm={handleDelete}
                    onCancel={() => setShowDeleteModal(false)}
                    message={
                        deleteType === 'facility'
                            ? 'Delete This Facility?'
                            : deleteType === 'amendment'
                                ? 'Delete This Facility Amendment?'
                                : 'Are you sure?'
                    }
                />
            )}
        </div>
    )
}
