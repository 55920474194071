import { Stack, Group, MultiSelect, MultiSelectProps } from '@mantine/core'
import IonIcon from '@reacticons/ionicons'
import MultiSelectOptionItem from './multi-select-option-item'
import SelectedItemBadge from './selected-item-badge'
import '../../../../styles/components/multi-select-search-input.css'


interface SearchInputProps extends MultiSelectProps {
  data: { label: string, value: string, isHeader?: boolean, disabled?: boolean }[]
}

export default function MultiSelectSearchInput({ placeholder = 'Search', data, value, ...props }: SearchInputProps) {

  return (
    <Stack w='100%' className='search-input-stack'>
      <Group w='100%' className='search-input-group'>
        <MultiSelect
          placeholder={placeholder}
          data={data}
          className='input-search'
          searchable
          value={value}
          rightSection={
            <Group className='search-input-icon-wrapper'>
              <IonIcon name='search-outline' size='large' color='black' className='search-input-icon' />
            </Group>
          }
          itemComponent={MultiSelectOptionItem}
          valueComponent={SelectedItemBadge}
          maxDropdownHeight={500}
          transitionProps={{ duration: 150, transition: 'pop-top-left', timingFunction: 'ease' }}
          {...props}
        />
      </Group>
    </Stack>
  )
}