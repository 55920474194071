import React, { useState } from 'react';
import { Box, Grid, Col, Group } from '@mantine/core';
import IonIcon from '@reacticons/ionicons';
import DeleteConfirmation from 'app/views/components/delete-confirmation/DeleteConfirmation';

interface ActionButtonsProps {
  createAssignment: () => void;
  editAssignment: () => void;
  deleteAssignment: () => void;
  disabled: boolean;
  disabledEditDelete: boolean;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  createAssignment,
  editAssignment,
  deleteAssignment,
  disabled,
  disabledEditDelete,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete confirmation modal

  const handleDelete = () => {
    deleteAssignment(); // Trigger delete action
    setShowDeleteModal(false); // Close modal after confirmation
  };

  return (
    <Box style={{ marginTop: '15px' }}>
      <Grid>
        <Col span={3}>
          <Group>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon
                size="large"
                name="add-circle-outline"
                aria-disabled={disabled}
                style={{
                  backgroundColor: disabled ? 'white' : '',
                  borderColor: disabled ? 'white' : '',
                  cursor: disabled ? 'not-allowed' : 'pointer',
                }}
                onClick={!disabled ? createAssignment : undefined}
              />
              <span
                style={{
                  marginLeft: '8px',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: 'bold',
                  fontSize: '1em',
                }}
              >
                Add Assignment
              </span>
            </div>
          </Group>
        </Col>
        <Col span={3}>
          <Group>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon
                size="large"
                name="create-outline"
                aria-disabled={disabled || disabledEditDelete}
                style={{
                  backgroundColor:
                    disabled || disabledEditDelete ? 'white' : '',
                  borderColor: disabled || disabledEditDelete ? 'white' : '',
                  cursor:
                    disabled || disabledEditDelete ? 'not-allowed' : 'pointer',
                }}
                onClick={
                  !(disabled || disabledEditDelete) ? editAssignment : undefined
                }
              />
              <span
                style={{
                  marginLeft: '8px',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: 'bold',
                  fontSize: '1em',
                }}
              >
                Edit Assignment
              </span>
            </div>
          </Group>
        </Col>
        <Col span={3}>
          <Group>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon
                size="large"
                name="trash-outline"
                aria-disabled={disabled || disabledEditDelete}
                style={{
                  backgroundColor:
                    disabled || disabledEditDelete ? 'white' : '',
                  borderColor: disabled || disabledEditDelete ? 'white' : '',
                  cursor:
                    disabled || disabledEditDelete ? 'not-allowed' : 'pointer',
                }}
                onClick={
                  !(disabled || disabledEditDelete)
                    ? () => setShowDeleteModal(true)
                    : undefined
                }
              />
              <span
                style={{
                  marginLeft: '8px',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: 'bold',
                  fontSize: '1em',
                }}
              >
                Delete Assignment
              </span>
            </div>
          </Group>
        </Col>
      </Grid>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <DeleteConfirmation
          onConfirm={handleDelete}
          onCancel={() => setShowDeleteModal(false)}
          message="Delete This Assignment?"
        />
      )}
    </Box>
  );
};

export default ActionButtons;
