import React, { useEffect, useState } from 'react';
import { Paper, Group, Button, TextInput } from '@mantine/core';
import StatusSwitch from '../../components/status-switch';
import RolesSection from './roles-section';
import { EntityTypeParams, EntityTypes, EntityTypeHierarchy } from 'app/models/entity-type-params';
import { useDispatch } from 'react-redux';
import { saveEntityType } from 'app/state/ducks/entity-type/thunks';
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification';
import { useForm } from '@mantine/form';
import { NewSelectInput } from 'app/views/components/inputs/selects/NewSelectInput';
import { entityTypeHierarchyOptions } from 'app/models/dropdown-options';


interface EntityFormProps {
    newEntity?: EntityTypeParams;
    setFormExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const EntityForm: React.FC<EntityFormProps> = ({ newEntity, setFormExpanded }) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const handleCreateEntity = async () => {
        setIsLoading(true)
        try {
            const validatedForm = form.validate()
            if (validatedForm.hasErrors) return
            const values = form.values;
            const result: any = await dispatch(saveEntityType(values, values.id));
            if (!result.success) {
                ErrorNotification({ message: result.payload, title: 'Save Entity Type Failed' });
                return;
            }
            SuccessNotification({
                message: 'The entity type has been successfully saved.',
                title: 'Save Successful',
            });
            setFormExpanded(false);
            form.reset();
        } catch (error) {
            console.log(error)
            ErrorNotification({
                message: 'An error occurred while saving the entity type.',
                title: 'Save Failed',
            });
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        if (newEntity) {
            form.setValues(newEntity);
        }
    }, [newEntity]);

    const form: any = useForm({
        initialValues: newEntity ?? {
            name: '',
            mandatoryFields: [] as EntityTypes[],
            hierarchy: '' as EntityTypeHierarchy,
            isActive: false
        },
       validate: {
            name: (value) => (value.trim() === '' ? 'Name is required' : null),
            hierarchy: (value) => (value.trim() === '' ? 'Hierachy is required' : null),
            mandatoryFields: (value) => (value.length === 0 ? 'At least one mandatory field is required' : null),
        },
        validateInputOnBlur: true,
        validateInputOnChange: true,
    })

    return (
        <Paper
            p="lg"
            radius="md"
            className="entity-form-paper"
            style={{ borderRadius: newEntity ? '0px 0px 12px 12px' : '12px', }}
        >
            <form onSubmit={handleCreateEntity}>
                <Group spacing="lg" mb="md" sx={{ width: '100%', gap: '10px' }}>
                    <Group sx={{ flex: 4, width: '100%' }}>
                        <TextInput
                            placeholder="Add Entity Type Name"
                            className="entity-name-input"

                            {...form.getInputProps('name')}
                        />
                    </Group>
                    <Group sx={{ flex: 1, width: '100%' }}>
                        <NewSelectInput label="Hierarchy" data={entityTypeHierarchyOptions} w="195px" placeholder='Hierarchy' {...form.getInputProps('hierarchy')} />
                    </Group>
                </Group>

                <RolesSection
                    roles={form.values.mandatoryFields}
                    hierarchy={form.values.hierarchy}
                    onToggleRole={(role, checked) => {
                        const updatedRoles = checked
                            ? [...form.values.mandatoryFields, role]
                            : form.values.mandatoryFields.filter((r: EntityTypes) => r !== role);
                        form.setFieldValue('mandatoryFields', updatedRoles);
                    }}
                    errorMsg={form.errors.mandatoryFields || null}
                />

                <Group align="center" mb="md" sx={{ width: '100%' }} position="apart">
                    <StatusSwitch
                        isActive={form.values.isActive}
                        onToggle={(isActive) => form.setFieldValue('isActive', isActive)}
                    />
                    <Group className="form-actions-group">
                        <Button
                            variant="outline"
                            onClick={() => setFormExpanded(false)}
                            className="form-actions-button cancel"
                            loading={isLoading}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleCreateEntity}
                            className="form-actions-button save"
                            loading={isLoading}
                        >
                            Save
                        </Button>
                    </Group>
                </Group>
            </form>
        </Paper>
    );
};

export default EntityForm;
