import React, { useEffect, useState } from 'react';
import { Stack } from '@mantine/core';
import { useSelector } from 'react-redux';
import { DetailSection } from 'app/views/components/detailSections/detail-section';
import { getEntities } from 'app/state/ducks/entities/selectors';
import { getLoanDetailsObjectArray } from './locs-details';
import { LetterOfCreditParams } from 'app/models/letter-of-credit';


interface DetailPanelProps {
  row: LetterOfCreditParams;
  isExpanded: boolean;
}

const LocsDetailPanel: React.FC<DetailPanelProps> = ({ row }) => {
  const entities = useSelector(getEntities);
  const [loanDetailsObjectArray, setLoanDetailsObjectArray] = useState<any[]>([]);

  useEffect(() => {
    setLoanDetailsObjectArray(
      getLoanDetailsObjectArray({
        row,
        entities,
      })
    );
  }, [row, entities]);

  return (
    <Stack w="100%" pt="12px" className="sectionDetailBody noHeader">
      <DetailSection detailObjArr={loanDetailsObjectArray} columnCount={4} />
    </Stack>
  );
};

export default LocsDetailPanel;
