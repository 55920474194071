import React, { useEffect, useState } from 'react'
import { Box, Stack } from '@mantine/core'
import { useSelector } from 'react-redux'
import { MasterTradeParams } from 'app/models/master-trade-params'
import { getMasterTradeLoader, getMasterTrades } from 'app/state/ducks/master-trades/selectors'
import ExpandableMantineTable from './trade-enty-table'

export function TradeEntry() {
  const masterTrades: MasterTradeParams[] = useSelector(getMasterTrades)
  const [globalLoading, setGlobalLoading] = useState(false);
  const masgterTradeLoader = useSelector(getMasterTradeLoader);

  useEffect(() => {
    setGlobalLoading(masgterTradeLoader);
  }, [masgterTradeLoader]);


  return (
    <Stack>
      <Box style={{ marginBottom: '20px' }}><ExpandableMantineTable title={'Trade List'} masterTrades={masterTrades} globalLoading={globalLoading} /></Box>
    </Stack>
  )
}
