import { Badge } from '@mantine/core'
import '../../../../styles/components/multi-select-search-input.css'


// Selected Items that appear when an item is selected within the MultiSelect input element
const SelectedItemBadge = ({ label, onRemove }: { label: string; onRemove?: () => void }) => {
    const [leftLabel] = label.split('|') // Extract only the leftLabel (item on left side of pipe symbol) to show in the Badge
    return (
        <Badge
            className='selected-item-badge'
            rightSection={
                <span
                    onClick={onRemove}
                    className='selected-item-badge-close'
                >
                    x
                </span>
            }
        >
            {leftLabel}
        </Badge>
    )
}

export default SelectedItemBadge;