import { Menu, Modal } from '@mantine/core'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import { useState } from 'react'
import { getDeals } from 'app/state/ducks/deals/selectors'
import { DealParams } from 'app/models/deal-params'
import { useDispatch, useSelector } from 'react-redux'
import {
  approveDeal,
  deleteDeal,
  rejectDeal,
  submitDeal,
} from './deal-daml-actions'
import {
  deleteDealAmendment,
  submitDealAmendment,
  approveDealAmendment,
  rejectDealAmendment,
} from '../deal-amendment-details/deal-amendment-daml-actions'
import { getDealAmendments } from 'app/state/ducks/deal-amendments/selectors'
import HistoryForm from './history/history'
import { useNavigate } from 'react-router-dom'
import { UpdatedFacilitiesScreen } from '../../facilities/updated-facilities-screen'
import DealTerminationForm from '../locked-deals/deal-termination-form'
import IonIcon from '@reacticons/ionicons'
import DealsForm from './deal-form'
import { loadDealSnapshot } from 'app/state/ducks/deals/thunks'
import { generateHTML } from './../preview/generateHTML';
import { FacilitySnapshotParams } from 'app/models/snapshot-params'
import { formatDateSnapshot, formatNumberToCurrency } from 'app/utils/util-functions'
import PrimaryInput from 'app/views/components/buttons/PrimaryInput'
import DeleteConfirmation from 'app/views/components/delete-confirmation/DeleteConfirmation'

type Props = {
  row: DealParams
  withRedirection?: boolean
  extended?: boolean
  primaryInput?: boolean
}

export default function DealActions({ row, withRedirection, extended, primaryInput }: Props) {
  const [openEdit, setOpenEdit] = useState(false)
  const [deal, setDeal] = useState<DealParams | undefined>(undefined)
  const [dealAmendment, setDealAmendment] = useState<DealParams | null>(null)
  const [openFacility, setOpenFacility] = useState(false)
  const [amendment, setAmendment] = useState<string | null>(null)
  const [openHistory, setOpenHistory] = useState(false)
  const [openTerminate, setOpenTerminate] = useState(false)
  const navigate = useNavigate()
  const deals: DealParams[] = useSelector(getDeals)
  const dealAmendments: DealParams[] = useSelector(getDealAmendments)
  const dispatch = useDispatch()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [deleteType, setDeleteType] = useState<'deal' | 'amendment' | null>(null); // State for delete type

  function editDeal(dealId: string) {
    setDeal(deals.find(f => f.id == dealId))
    setOpenEdit(true)
  }

  function amendDeal(dealId: string) {
    setDeal(deals.find(f => f.id == dealId))
    const amended_deal = dealAmendments?.find(
      amendment => amendment.id === dealId
    )
    setDealAmendment(amended_deal ?? null)
    setAmendment('amend')
    setOpenEdit(true)
  }

  function terminateDeal(dealId: string) {
    setDeal(deals.find(f => f.id == dealId))
    const amended_deal = dealAmendments?.find(
      amendment => amendment.id === dealId
    )
    setDealAmendment(amended_deal ?? null)
    setOpenTerminate(true)
  }

  function displayHistory(): any {
    setDeal(row)
    setOpenHistory(true)
  }

  async function submitCurrentDeal() {
    const result = await submitDeal(row, dispatch)
    if (result && withRedirection) {
      navigate(`/dealmanagement/deal/${row.id}/Submitted`)
    }
  }

  async function approveCurrentDeal() {
    const result = await approveDeal(row, dispatch)
    if (result && withRedirection) {
      navigate(`/dealmanagement/deal/${row.id}/Approved`)
    }
  }

  async function rejectCurrentDeal() {
    const result = await rejectDeal(row, dispatch)
    if (result && withRedirection) {
      navigate(`/dealmanagement/deal/${row.id}/Draft`)
    }
  }
  async function submitCurrentDealAmendment() {
    const result = await submitDealAmendment(row, dispatch)
    if (result && withRedirection) {
      navigate(`/dealmanagement/deal/${row.id}/Subbmitted`)
    }
  }
  async function approveCurrentDealAmendment() {
    const result = await approveDealAmendment(row, dispatch)
    if (result && withRedirection) {
      navigate(`/dealmanagement/deal/${row.id}/Approved`)
    }
  }
  async function rejectCurrentDealAmendment() {
    const result = await rejectDealAmendment(row, dispatch)
    if (result && withRedirection) {
      navigate(`/dealmanagement/deal/${row.id}/Draft`)
    }
  }

  const handleDelete = async () => {
    if (deleteType === 'deal') {
      await deleteDeal(row, dispatch);
      if (withRedirection) navigate(`/dealmanagement`);
    } else if (deleteType === 'amendment') {
      const result = await deleteDealAmendment(row, dispatch);
      if (result && withRedirection) navigate(`/dealmanagement/deal/${row.id}/Approved`);
    }
    setOpenDeleteModal(false); // Close modal
  };


  const openPreview = async (row: any) => {
    const dealSnapshot = await loadDealSnapshot(row.id)
    row = dealSnapshot.payload
    const { monthDayYear } = formatDateSnapshot();
    let previewHTML = ""
    previewHTML = `
<!DOCTYPE html>
  <html>
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Deal & Facilities</title>
        <link rel="stylesheet" type="text/css" href="/previewStyles.css" />
    </head>
    <body>
        <div id="content">
            <header>
                <div>
                    <h1>Deal Snapshot</h1>
                </div>
                <div>As of ${monthDayYear}</div>
            </header>

            <main>
                <!-- Overview -->
                <div id="overview">
                    <div id="overview-container">
                        <div class="heading">
                            <h3>Deal Name: </h3>
                            <h2>${row.dealName}</h2>
                        </div>
                        <h3>Deal Details:</h3>
                        <table class="details-table details-overview">
                            <tbody>
                                <tr>
                                    <td><strong>Agent:</strong></td>
                                    <td>${row.agent}</td>
                                </tr>
                                <tr>
                                    <td><strong>Borrowers:</strong></td>
                                    <td>${row.borrowers}</td>
                                </tr>
                                <tr>
                                    <td><strong>Guarantors:</strong></td>
                                    <td>${row.guarantors}</td>
                                </tr>
                                <tr>
                                    <td><strong>Amount:</strong></td>
                                    <td>${formatNumberToCurrency(Number(row.amount), row.currency)}</td>
                                </tr>
                                <tr>
                                    <td><strong>Currency:</strong></td>
                                    <td>${row.currency}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="details-table details-overview">
                            <tbody>
                                <tr>
                                    <td><strong>Start Date:</strong></td>
                                    <td>${row.startDate}</td>
                                </tr>
                                <tr>
                                    <td><strong>Maturity Date:</strong></td>
                                    <td>${row.maturityDate}</td>
                                </tr>
                                <tr>
                                    <td><strong>Deal CUSIP</strong></td>
                                    <td>${row.dealCUSIP}</td>
                                </tr>
                                 <tr>
                                    <td><strong>Deal ISIN</strong></td>
                                    <td>${row.dealISIN}</td>
                                </tr>
                                <tr>
                                    <td><strong>Deal LXID:</strong></td>
                                    <td>${row.dealLXID}</td>
                                </tr>
                                 <tr>
                                    <td><strong>Deal BBG ID:</strong></td>
                                    <td>${row.dealBBGID}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            ${row?.facilities?.map((facility: FacilitySnapshotParams, index: number) => generateHTML(facility, index + 1)).join('')}
            </main>
        </div>
    </body>
  </html>`;

    const newWindow = window.open("", "_blank")
    if (newWindow) {
      newWindow.document.write(previewHTML)
      newWindow.document.close()
    }
  }

  return (
    <div>
      <Modal
        className="modal-body modal-body-template fit-content-modal"
        opened={openEdit}
        onClose={() => setOpenEdit(false)}
        centered={true}
        size="77%"
      >
        <DealsForm
          closeModal={() => setOpenEdit(false)}
          deal={deal ?? {}}
          useStepper={false}
          amendment={amendment}
          dealAmendment={dealAmendment}
        />
      </Modal>
      <Menu shadow="md" width={primaryInput ? '250px' : 200} position="bottom" withinPortal>
        <Menu.Target>
          <div style={{ width: 'fit-content' }}>
            {primaryInput ?
              <PrimaryInput w='159px'>
                Actions
                <IonIcon name='chevron-down-outline' className='primaryInputChevronDown' />
              </PrimaryInput>
              :
              <PrimaryDropDown>Actions</PrimaryDropDown>
            }
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          {row?.status?.toLowerCase() == 'draft' && !row?.amendment ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
              onClick={() => editDeal(row.id)}
            >
              Edit
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'draft' && row?.amendment ? (
            row?.amendmentType?.toLowerCase() === 'termination' ? (
              <Menu.Item
                icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                onClick={() => {
                  terminateDeal(row.id)
                }}
              >
                Terminate
              </Menu.Item>
            ) : (
              <Menu.Item
                icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                onClick={() => {
                  amendDeal(row.id)
                }}
              >
                Amendment
              </Menu.Item>
            )
          ) : null}

          {row?.status?.toLowerCase() == 'approved' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
              onClick={() => openPreview(row)}
            >
              Snapshot
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'draft' && row?.amendment ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => submitCurrentDealAmendment()}
            >
              Submit For Approval
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'draft' && !row?.amendment ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => submitCurrentDeal()}
            >
              Submit For Approval
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'submitted' && row?.amendment ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => approveCurrentDealAmendment()}
            >
              Approve
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'submitted' && !row?.amendment ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => approveCurrentDeal()}
            >
              Approve
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'approved' ||
            row?.amendment?.status?.toLowerCase() === '' ? (
            <>
              <Menu.Item
                icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                onClick={() => {
                  amendDeal(row.id)
                }}
              >
                Amendment
              </Menu.Item>
              <Menu.Item
                icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                onClick={() => {
                  terminateDeal(row.id)
                }}
              >
                Terminate
              </Menu.Item>
            </>
          ) : null}
          {extended ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
              onClick={() => {
                setOpenFacility(true)
              }}
            >
              Create Facility
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() != 'approved' ||
            row?.amendment?.status?.toLowerCase() === 'draft' ? (
            <>
              <Menu.Divider />
              <Menu.Label>Danger zone</Menu.Label>
            </>
          ) : null}

          {row?.status?.toLowerCase() == 'draft' && row?.amendment ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name="trash-outline" />}
              onClick={() => {
                setDeleteType('amendment'); // Set delete type for amendment
                setOpenDeleteModal(true);
              }}
            >
              Delete
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'draft' && !row?.amendment ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name="trash-outline" />}
              onClick={() => {
                setDeleteType('deal'); // Set delete type for deal
                setOpenDeleteModal(true);
              }}
            >
              Delete
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'submitted' && row?.amendment ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => rejectCurrentDealAmendment()}
            >
              Reject
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'submitted' && !row?.amendment ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => rejectCurrentDeal()}
            >
              Reject
            </Menu.Item>
          ) : null}
          <Menu.Item
            onClick={() => displayHistory()}
            icon={<IonIcon className="action-ion-icon" name='arrow-undo-circle-outline' />}
          >
            History
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <Modal
        size="90%"
        opened={openHistory}
        onClose={() => setOpenHistory(false)}
      >
        {deal && (
          <HistoryForm
            dealId={deal.id ?? ''}
            close={() => setOpenHistory(false)}
          />
        )}
      </Modal>
      <Modal
        className="modal-body modal-body-template fit-content-modal"
        size="xl"
        // style={{ height: '80%' }}
        opened={openTerminate}
        onClose={() => setOpenTerminate(false)}
      >
        {deal && (
          <DealTerminationForm
            deal={dealAmendment ?? deal}
            close={() => setOpenTerminate(false)}
          />
        )}
      </Modal>
      <Modal
        className="modal-body modal-body-template fit-content-modal"
        opened={openFacility}
        onClose={() => setOpenFacility(false)}
        centered={true}
        size="77%"
      >
        <UpdatedFacilitiesScreen
          deal={row}
          facility={undefined}
          onClose={() => setOpenFacility(false)}
        />
      </Modal>
      {/* Delete Confirmation Modal */}
      {openDeleteModal && (
        <DeleteConfirmation
          onConfirm={handleDelete}
          onCancel={() => setOpenDeleteModal(false)}
          message={
            deleteType === 'deal'
              ? 'Delete This Deal?'
              : deleteType === 'amendment'
                ? 'Delete This Deal Amendment?'
                : 'Are you sure?'
          }
        />
      )}
    </div>
  )
}
